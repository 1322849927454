import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
  useTheme,
} from "@mui/material";
import { CalendarToday, Person } from "@mui/icons-material"; // Icônes pour la date et l'auteur
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import colors from "../../config/colors";

type blogCardInterface = {
  image: string;
  title: string;
  description: string;
  author: string;
  date: string;
};

export default function BlogCard({
  image,
  title,
  description,
  author,
  date,
}: blogCardInterface) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        width: "100%",
        borderRadius: 2,
        overflow: "hidden",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        transition: "transform 0.3s",
        "&:hover": {
          transform: "translateY(-5px)",
        },
        backgroundColor: colors.white,
      }}
    >
      {/* Image de la carte */}
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{
          width: "100%",
          height: 250,
          cursor: "pointer",
        }}
      />

      {/* Contenu de la carte */}
      <CardContent sx={{ padding: 3 }}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Person sx={{ fontSize: 18, color: theme.palette.primary.main }} />
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ cursor: "pointer" }}
            >
              {author}
            </Typography>
          </Grid>
          <Grid item>
            <CalendarToday
              sx={{
                fontSize: 18,
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ cursor: "pointer" }}
            >
              {date}
            </Typography>
          </Grid>
        </Grid>

        {/* Titre du blog */}
        <Typography
          variant="h6"
          fontWeight={700}
          sx={{
            mt: 2,
            mb: 1,
            color: "#333",
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          {title}
        </Typography>

        {/* Description / Extrait */}
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ mb: 2, height: "60px", overflow: "hidden" }}
        >
          {description}
        </Typography>

        {/* Lien vers l'article */}
        <Box display="flex" alignItems="center" color="primary.main">
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{ color: theme.palette.primary.main, mr: 1, cursor: "pointer" }}
          >
            Read More
          </Typography>
          <ArrowForwardIcon sx={{ color: theme.palette.primary.main }} />
        </Box>
      </CardContent>
    </Card>
  );
}
