import React from "react";
import Title from "../../../components/title/Title";
import { Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useResponsiveQueries } from "../../../config/useResponsiveQueries";

export default function HomeTrushUsContent() {
  const { mobileMaxWidth } = useResponsiveQueries();
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "stretch",
        }}
      >
        <Grid
          sx={{
            textAlign: "left",
            margin: 1,
            width: "100%",
            mt: 4,
          }}
        >
          <Title
            title={"WHY TRUST US?".toUpperCase()}
            fontSize={mobileMaxWidth ? 18 : 20}
            fontWeight={600}
            style={{}}
          />
          <br />
          <Typography
            fontSize={24}
            fontWeight={700}
            color={theme.palette.primary.dark}
          >
            Partner with us to achieve your business goals with confidence and trust.
          </Typography>
          <br />
          <Typography
            fontSize={16}
            fontWeight={400}
            color={theme.palette.primary.dark}
          >
            Trusting us means partnering with a company that is committed to your success.
            We offer a wide range of IT services designed to help you achieve your business goals. 
            Let us help you take your business to the next level.
          </Typography>
        </Grid>
        <Grid sx={{ mt: 1 }}></Grid>
        <Grid
          sx={{
            mt: 1,
            bgcolor: "transparent",
            width: "100%",
            maxWidth: 495,
          }}
        ></Grid>
      </Grid>
    </Grid>
  );
}
