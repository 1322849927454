import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const image_page_not_found = require('../../assets/404 Error-rafiki.png');

export default function PageNotFound() {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Grid
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: theme.palette.primary.light
      }}
    >
      <img
        src={image_page_not_found}
        alt={'page not found'}
        style={{ maxWidth: '50%', height: 'auto' }}
      />
      <Button
        onClick={() => navigate('/')}
        variant="outlined"
        sx={{
          margin: 2,
          color: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.main,
          ':hover': {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
            borderColor: theme.palette.primary.main
          }
        }}
      >
        Go back home
      </Button>
    </Grid>
  );
}
