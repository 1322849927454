import React, { useContext, useState } from 'react';
import {
  Grid,
  Box,
  Paper,
  InputAdornment,
  Typography,
  useTheme
} from '@mui/material';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import DialpadIcon from '@mui/icons-material/Dialpad';
import Phone from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';

import {
  getLocalStorageEmployerAddress,
  getLocalStorageEmployerInfo,
  getLocalStorageCompanyAddress,
  getLocalStorageCompanyInfo
} from '../config/localStorage';
import { addCompany } from '../apis/company.api';
import { CompanyAddressInterface } from '../interfaces/companyCreation/companyAddress.interface';
import Toast from '../components/toast/Toast.jsx';
import { CompanyInfoInterface } from '../interfaces/companyCreation/companyInfo.interface';
import { EmployerAddressInterface } from '../interfaces/companyCreation/employerAddress.interface';
import { EmployeeInfoInterface } from '../interfaces/companyCreation/employerInfo.interface';
import { Step4Interface } from '../interfaces/stepped/step4.interface';
import PrimaryButton from '../components/forms/buttons/PrimaryButton';
import TextInput from '../components/forms/textField/TextInput';
import Loading from '../components/loading/Loading';
import AppContext from '../config/appContext';

export default function Step5({
  handleBack,
  activeStep,
  steps
}: Step4Interface) {
  const theme = useTheme();
  const { payMethod } = useContext(AppContext);
  const employeAddress = getLocalStorageEmployerAddress();
  const employeInfo = getLocalStorageEmployerInfo();
  const companyAddress = getLocalStorageCompanyAddress();
  const companyInfo = getLocalStorageCompanyInfo();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [badRequestError, setBadRequestError] = useState(false);

  const navigate = useNavigate();

  const data_companyInfo: CompanyInfoInterface = {
    companyName: companyInfo.companyName != null ? companyInfo.companyName : '',
    email: companyInfo.email != null ? companyInfo.email : '',
    // paymentMethod:
    //   companyInfo.paymentMethod != null ? companyInfo.paymentMethod : '',
    numberOfLocations:
      companyInfo.numberOfLocations != null
        ? Number(companyInfo.numberOfLocations)
        : 0,
    servicePlan: companyInfo.servicePlan != null ? companyInfo.servicePlan : '',
    type: companyInfo.type != null ? companyInfo.type : '',
    companyFormation:
      companyInfo.companyFormation != null ? companyInfo.companyFormation : ''
  };

  const data_companyAddress: CompanyAddressInterface = {
    addressLine1:
      companyAddress.addressLine1 != null ? companyAddress.addressLine1 : '',
    addressLine2:
      companyAddress.addressLine2 != null ? companyAddress.addressLine2 : '',
    city: companyAddress.city != null ? companyAddress.city : '',
    phoneNumber:
      companyAddress.phoneNumber != null ? companyAddress.phoneNumber : '',
    state: companyAddress.state != null ? companyAddress.state : '',
    zipCode: companyAddress.zipCode != null ? companyAddress.zipCode : '',
    country: companyAddress.country != null ? companyAddress.country : '',
    countryCode:
      companyAddress.countryCode != null ? companyAddress.countryCode : ''
  };

  const data_employeInfo: EmployeeInfoInterface = {
    firstName: employeInfo.firstName != null ? employeInfo.firstName : '',
    email: employeInfo.email != null ? employeInfo.email : '',
    userName: employeInfo.userName != null ? employeInfo.userName : '',
    pin: employeInfo.pin != null ? Number(employeInfo.pin) : 0,
    lastName: employeInfo.lastName != null ? employeInfo.lastName : '',
    dateOfBirth:
      employeInfo.dateOfBirth != null ? employeInfo.dateOfBirth : new Date()
  };

  const data_employeAddress: EmployerAddressInterface = {
    addressLine1:
      employeAddress.addressLine1 != null ? employeAddress.addressLine1 : '',
    phoneNumber:
      employeAddress.phoneNumber != null ? employeAddress.phoneNumber : '',
    addressLine2:
      employeAddress.addressLine2 != null ? employeAddress.addressLine2 : '',
    city: employeAddress.city != null ? employeAddress.city : '',
    state: employeAddress.state != null ? employeAddress.state : '',
    zipCode: employeAddress.zipCode != null ? employeAddress.zipCode : '',
    country: employeAddress.country != null ? employeAddress.country : ''
  };

  const submitForm = async () => {
    setLoading(true);
    // console.log(
    //   data_companyInfo,
    //   payMethod,
    //   data_companyAddress,
    //   data_employeInfo,
    //   data_employeAddress
    // );
    data_companyAddress.phoneNumber = `+${data_companyAddress.countryCode}${data_companyAddress.phoneNumber}`;
    await addCompany(
      payMethod,
      data_companyInfo,
      data_companyAddress,
      data_employeInfo,
      data_employeAddress
    )
      .then(async (res: any) => {
        if (res) {
          setLoading(false);
          if (res?.data?.status === 200) {
            if (res?.data?.data?.data?.companyInfoCreateOne) {
              setTimeout(() => {
                navigate('/resetPassword');
              }, 3000);
              setError(false);
              setServerError(false);
              setBadRequestError(false);
              setSuccess(true);
              localStorage.clear();
            } else {
              setError(true);
              setServerError(false);
              setBadRequestError(false);
              setSuccess(false);
            }
          } else if (res?.data?.status === 500) {
            setError(false);
            setServerError(true);
            setBadRequestError(false);
            setSuccess(false);
          } else {
            // res?.data?.status === 400
            setError(false);
            setServerError(false);
            setBadRequestError(true);
            setSuccess(false);
          }
        } else {
          setLoading(true);
        }
      })
      .catch((err: any) => err);
  };

  return (
    <Paper sx={{ padding: 5, margin: 1, marginBottom: '10%' }}>
      {/* Loading */}
      <Grid item sx={{ marginBottom: 1, marginTop: 1 }}>
        {loading && <Loading isLoading={loading} loadingMsg={'Loading...'} />}
      </Grid>
      {/* Company information */}
      <Grid item sx={{ margin: 2 }}>
        {error && (
          <Toast
            error={
              'The username or email already exists. Please check and try again!'
            }
            success={undefined}
            warning={undefined}
            info={undefined}
          />
        )}
      </Grid>
      <Grid item sx={{ margin: 2 }}>
        {serverError && (
          <Toast
            error={'Oops an error occurred please try again!'}
            success={undefined}
            warning={undefined}
            info={undefined}
          />
        )}
      </Grid>
      <Grid item sx={{ margin: 2 }}>
        {badRequestError && (
          <Toast
            error={
              'Oops, something went wrong. Please check that the information you entered is correct and try again.'
            }
            success={undefined}
            warning={undefined}
            info={undefined}
          />
        )}
      </Grid>
      <Grid item sx={{ margin: 2 }}>
        {success && (
          <Toast
            success={
              "The company's account has been successfully created. You will soon receive a confirmation email."
            }
            error={undefined}
            warning={undefined}
            info={undefined}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          margin: 2,
          padding: 3,
          '--Grid-borderWidth': '1px',
          borderTop: 'var(--Grid-borderWidth) solid',
          borderLeft: 'var(--Grid-borderWidth) solid',
          borderRight: 'var(--Grid-borderWidth) solid',
          borderBottom: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider'
        }}
      >
        <Grid
          sx={{
            px: { xs: 2, md: 7 },
            pb: { xs: 2, md: 3 },
            marginBottom: 2,
            marginTop: 2,
            textAlign: 'justify'
          }}
        >
          <Typography fontSize={18} fontWeight={700}>
            Company Information
          </Typography>
        </Grid>
        <Grid
          sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
          container
          justifyContent="space-between"
        >
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Company Name"
              value={companyInfo.companyName}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <AddBusinessIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Company Email"
              value={companyInfo.email}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <EmailIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Payment Method"
              value={payMethod}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <PermIdentityIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Service Plan"
              value={companyInfo.servicePlan}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <PermIdentityIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Company Type"
              value={companyInfo.companyFormation}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <PermIdentityIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Number Of Locations"
              value={companyInfo.numberOfLocations}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <ApartmentIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Company address, */}
      <Grid
        item
        xs={12}
        sx={{
          margin: 2,
          padding: 3,
          '--Grid-borderWidth': '1px',
          borderTop: 'var(--Grid-borderWidth) solid',
          borderLeft: 'var(--Grid-borderWidth) solid',
          borderRight: 'var(--Grid-borderWidth) solid',
          borderBottom: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider'
        }}
      >
        <Grid
          sx={{
            px: { xs: 2, md: 7 },
            pb: { xs: 2, md: 3 },
            marginBottom: 2,
            marginTop: 2,
            textAlign: 'justify'
          }}
        >
          <Typography fontSize={18} fontWeight={700}>
            Company address
          </Typography>
        </Grid>
        <Grid
          sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
          container
          justifyContent="space-between"
        >
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="AddressLine1"
              value={companyAddress.addressLine1}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <LocationOnIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="AddressLine2"
              value={companyAddress.addressLine2}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <LocationOnIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="City"
              value={companyAddress.city}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <LocationCityIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="State"
              value={companyAddress.state}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <PermIdentityIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Zip Code"
              value={companyAddress.zipCode}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <DialpadIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Country Code"
              value={companyAddress.countryCode}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <DialpadIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Company Phone"
              value={companyAddress.phoneNumber}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <Phone />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Country"
              value={companyAddress.country}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <LanguageIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Employer Information, */}
      <Grid
        item
        xs={12}
        sx={{
          margin: 2,
          padding: 3,
          '--Grid-borderWidth': '1px',
          borderTop: 'var(--Grid-borderWidth) solid',
          borderLeft: 'var(--Grid-borderWidth) solid',
          borderRight: 'var(--Grid-borderWidth) solid',
          borderBottom: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider'
        }}
      >
        <Grid
          sx={{
            px: { xs: 2, md: 7 },
            pb: { xs: 2, md: 3 },
            marginBottom: 2,
            marginTop: 2,
            textAlign: 'justify'
          }}
        >
          <Typography fontSize={18} fontWeight={700}>
            Employer Information
          </Typography>
        </Grid>
        <Grid
          sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
          container
          justifyContent="space-between"
        >
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="FirstName"
              value={employeInfo.firstName}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <PermIdentityIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              label="LastName"
              variant="filled"
              value={employeInfo.lastName}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <PermIdentityIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Email"
              value={employeInfo.email}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <EmailIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="UserName"
              value={employeInfo.userName}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <AccountCircle />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Date Of Birth"
              value={employeInfo.dateOfBirth}
              fullWidth
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <CalendarMonthIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Pin"
              value={employeInfo.pin}
              fullWidth
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <DialpadIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Employee address, */}
      <Grid
        item
        xs={12}
        sx={{
          margin: 2,
          padding: 3,
          '--Grid-borderWidth': '1px',
          borderTop: 'var(--Grid-borderWidth) solid',
          borderLeft: 'var(--Grid-borderWidth) solid',
          borderRight: 'var(--Grid-borderWidth) solid',
          borderBottom: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider'
        }}
      >
        <Grid
          sx={{
            px: { xs: 2, md: 7 },
            pb: { xs: 2, md: 3 },
            marginBottom: 2,
            marginTop: 2,
            textAlign: 'justify'
          }}
        >
          <Typography fontSize={18} fontWeight={700}>
            Employee address
          </Typography>
        </Grid>
        <Grid
          sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
          container
          justifyContent="space-between"
        >
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="AddressLine1"
              value={employeAddress.addressLine1}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <LocationOnIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="AddressLine2"
              value={employeAddress.addressLine2}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <LocationOnIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Country"
              value={employeAddress.country}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <LanguageIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="City"
              value={employeAddress.city}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <LocationCityIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="State"
              value={employeAddress.state}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <LanguageIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Phone Number"
              value={`+${companyAddress.countryCode} ${employeAddress.phoneNumber}`}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <Phone />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid sx={{ margin: 2 }}>
            <TextInput
              variant="filled"
              label="Zip Code"
              value={employeAddress.zipCode}
              fullWidth
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ marginRight: 1 }}>
                    <DialpadIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          padding: 2,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <PrimaryButton
          title="Back"
          variant="outlined"
          fontSize={16}
          fontWeight={500}
          disabled={activeStep === 0}
          onPress={handleBack}
          style={{
            width: '20%',
            height: 40,
            borderRadius: 1,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
            ':hover': {
              color: theme.palette.primary.light,
              backgroundColor: theme.palette.primary.main
            }
          }}
        />
        <Box sx={{ flex: '1 1 auto' }} />
        <PrimaryButton
          title={activeStep === steps.length - 1 ? 'Submit' : 'Next'}
          variant="outlined"
          fontSize={16}
          fontWeight={500}
          onPress={submitForm}
          style={{
            width: '20%',
            height: 40,
            borderRadius: 1,
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.main,
            ':hover': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light
            }
          }}
        />
      </Grid>
    </Paper>
  );
}
