import React from "react";
import { Typography, useTheme } from "@mui/material";
import { TitleInterface } from "../../interfaces/forms/title.interface";

function Title({
  title,
  subtitle,
  variant,
  fontSize,
  style,
  styleSubtitle,
  fontWeight,
}: TitleInterface) {
  const theme = useTheme();
  return (
    <Typography
      sx={style}
      variant={variant}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={theme.palette.secondary.main}
    >
      {title}
      <Typography
        sx={styleSubtitle}
        fontSize={fontSize ? fontSize - 2 : fontSize}
        fontWeight={200}
      >
        {subtitle}
      </Typography>
    </Typography>
  );
}

export default Title;
