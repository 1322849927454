import React from "react";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import NewAbout from "../../layouts/about/NewAbout";

export default function AboutScreen() {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        width: "100%",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: 1500,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <PictureMobileStepper pictures={imageAbout} /> */}
        <Grid item container sx={{ justifyContent: "center" }} xs={12}>
          <NewAbout />
        </Grid>
        {/* <Grid item container sx={{ justifyContent: 'center' }} xs={12}>
          <Contact />
        </Grid> */}
      </Grid>
    </Grid>
  );
}
