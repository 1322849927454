import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Grid, Typography, useTheme } from "@mui/material";
import { useResponsiveQueries } from "../../../config/useResponsiveQueries";
import PrimaryButton from "../../../components/forms/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import colors from "../../../config/colors";

export default function HomeHeaderContent() {
  const { tabletMaxWidth } = useResponsiveQueries();
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        display: "flex",
        padding: "0px var(--Spacing-spacing-5xl, 80px)",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "stretch",
        }}
      >
        <Grid
          sx={{
            textAlign: "left",
            margin: 1,
            width: "100%",
            mt: 4,
          }}
        >
          <Grid sx={{ display: "flex", justifyContent: "flex-start", my: 2 }}>
            <LinkedInIcon
              sx={{
                color: theme.palette.primary.light,
                height: 25,
                width: 25,
                p: 1,
                borderRadius: 2,
                mr: 2,
                cursor: "pointer",
                boxShadow: `0px 0px 0px 0.2px ${colors.mediumGray}`,
                ":hover": {
                  color: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                },
              }}
            />
            <FacebookIcon
              sx={{
                color: theme.palette.primary.light,
                height: 25,
                width: 25,
                p: 1,
                borderRadius: 2,
                mr: 2,
                cursor: "pointer",
                boxShadow: `0px 0px 0px 0.2px ${colors.mediumGray}`,
                ":hover": {
                  color: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                },
              }}
            />
            <XIcon
              sx={{
                color: theme.palette.primary.light,
                height: 25,
                width: 25,
                p: 1,
                borderRadius: 2,
                mr: 2,
                cursor: "pointer",
                boxShadow: `0px 0px 0px 0.2px ${colors.mediumGray}`,
                ":hover": {
                  color: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                },
              }}
            />
            <InstagramIcon
              sx={{
                color: theme.palette.primary.light,
                height: 25,
                width: 25,
                p: 1,
                borderRadius: 2,
                mr: 2,
                cursor: "pointer",
                boxShadow: `0px 0px 0px 0.2px ${colors.mediumGray}`,
                ":hover": {
                  color: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                },
              }}
            />
          </Grid>
          <Typography
            fontSize={tabletMaxWidth ? 40 : 50}
            fontWeight={"bold"}
            color={theme.palette.primary.light}
          >
            IT Solutions & Business Services Company
          </Typography>
          <br />
          <Typography
            fontSize={16}
            fontWeight={400}
            color={theme.palette.primary.light}
          >
            At our company, we specialize in delivering cutting-edge IT solutions and comprehensive business services tailored to meet the unique needs of our clients. Our team of experts is dedicated to providing innovative technology solutions that drive efficiency, enhance productivity, and foster growth. Whether you're looking to streamline your operations, implement new technologies, or improve your business processes, we have the expertise and experience to help you achieve your goals.
          </Typography>
        </Grid>
        <Grid sx={{ mt: 1 }}></Grid>
        <Grid
          sx={{
            mt: 1,
            bgcolor: "transparent",
            width: "100%",
            maxWidth: 495,
          }}
        >
          <PrimaryButton
            title="Our service"
            variant="outlined"
            fontSize={16}
            fontWeight={500}
            onPress={() => navigate("/services")}
            style={{
              width: tabletMaxWidth ? "100%" : 150,
              mr: 2,
              mt: 2,
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light,
              borderColor: theme.palette.primary.light,
              "&:hover": {
                color: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.dark,
                borderColor: theme.palette.background.paper,
              },
            }}
          />
          <PrimaryButton
            title="Contact Us"
            variant="outlined"
            fontSize={16}
            fontWeight={500}
            onPress={() => navigate("/contact")}
            style={{
              width: tabletMaxWidth ? "100%" : 150,
              mr: 2,
              mt: 2,
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              "&:hover": {
                color: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
