/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import {
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Alert,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PasswordIcon from "@mui/icons-material/Lock";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
// import { REGEX } from '../../constants/regex-validators';
import PrimaryButton from "../../components/forms/buttons/PrimaryButton";

const lightImages = require("../../assets/Computer login-rafiki.png");
const darkImages = require("../../assets/Computer login-rafiki (1).png");

export default function Login() {
  const navigate = useNavigate();
  const theme = useTheme();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [inputError, setInputError] = useState(false);
  const [, setAuthSuccess] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleUsername = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const tmp_username = event.target.value;
    setUsername(tmp_username);
  };
  const handlePassword = (event: any) => {
    const tmp_password = event.target.value;
    setPassword(tmp_password);
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    if (username) {
      setInputError(false);
      try {
      } catch (error: any) {
        // console.log('❌ Error signing in...', error.message);
        setAuthError(true);
      }
    } else {
      setInputError(true);
    }
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{
        backgroundColor: theme.palette.primary.light,
        flexDirection: { xs: "column-reverse", md: "row" },
        flexWrap: { xs: "wrap", md: "nowrap" },
        paddingTop: "118px",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{ margin: 2, order: { xs: 2, md: 2 } }}
        className="login-form"
      >
        <Grid container justifyContent="center">
          {theme.palette.mode === "dark" ? (
            <img src={darkImages} alt="image" style={{ width: "80%" }} />
          ) : (
            <img src={lightImages} alt="image" style={{ width: "80%" }} />
          )}
        </Grid>
      </Grid>
      <Paper
        sx={{
          width: { xs: "100%", md: "42%" },
          backgroundColor: theme.palette.primary.light,
          margin: {
            xs: 0, // Aucune marge pour les écrans de petite taille
            md: 2, // Marge de 2 pour les écrans de taille moyenne et plus grands
          },
        }}
      >
        <Grid item xs={12} sx={{ marginTop: 5 }}>
          <Grid
            item
            xs={12}
            sx={{
              margin: { xs: 2, md: 4 },
              textAlign: "center",
            }}
          >
            <Typography
              fontSize={28}
              fontWeight={500}
              color={theme.palette.primary.main}
            >
              Sign In
            </Typography>
          </Grid>
          {inputError && (
            <Alert severity="error" sx={{ margin: 4 }}>
              Please enter all required information correctly.
            </Alert>
          )}
          {authError && (
            <Alert severity="error" sx={{ margin: 4 }}>
              Incorrect username or password please try again!
            </Alert>
          )}
          <Grid
            item
            container
            direction="row"
            xs={12}
            justifyContent={"center"}
          >
            <Grid item xs={12} md={6} sx={{ margin: 2, padding: 2 }}>
              <TextField
                label="Username"
                placeholder="Username"
                value={username}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleUsername(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ margin: 2, padding: 2 }}>
              <TextField
                label="Enter Password"
                placeholder="Enter Password"
                required
                value={password}
                fullWidth
                id="standard-password-input"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="   password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handlePassword(e)}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ margin: { xs: 2, md: 4 }, textAlign: "center" }}
          >
            <PrimaryButton
              title="SIGN IN"
              variant="outlined"
              fontSize={16}
              fontWeight={500}
              onPress={submitForm}
              style={{
                width: "50%",
                height: 40,
                borderRadius: 1,
                color: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.main,
                ":hover": {
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.light,
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: 2,
            }}
          >
            <Typography fontSize={14} fontWeight={"520"}>
              Not registered?
              <Link href={"/company"}>
                {" "}
                <span style={{ color: theme.palette.primary.main }}>
                  Sign Up
                </span>
              </Link>
              <Grid
                sx={{
                  textAlign: "center",
                }}
              >
                <Link href={"/forgotPassword"}>
                  {" "}
                  <span style={{ color: theme.palette.primary.main }}>
                    Forgot password?
                  </span>
                </Link>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
