import colors from "./colors";

const palette = {
  light: {
    primary: {
      main: colors.primary,
      light: colors.white,
      dark: colors.black,
    },
    secondary: {
      main: colors.secondary,
      light: colors.white,
    },
    third: {
      main: colors.first,
    },
    success: {
      main: colors.success,
    },
    error: {
      main: colors.danger,
    },
  },
  dark: {
    primary: {
      main: colors.secondary,
    },
    secondary: {
      main: colors.lightGray,
      light: colors.darkGray,
    },
    third: {
      main: colors.third,
    },
    success: {
      main: colors.success,
    },
    error: {
      main: colors.danger,
    },
  },
};

export const getDesignTokens = (mode: any) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          primary: {
            main: palette.light.primary.main,
            light: palette.light.primary.light,
            dark: palette.light.primary.dark,
          },
          secondary: {
            main: palette.light.secondary.main,
            light: palette.light.secondary.light,
            dark: palette.dark.secondary.main,
          },
          success: {
            main: palette.light.success.main,
          },
          background: {
            paper: palette.light.third.main,
          },
          error: {
            main: palette.light.error.main,
          },
          text: {
            disabled: palette.light.third.main,
            primary: palette.light.primary.main,
            secondary: palette.dark.third.main,
          },
        }
      : {
          primary: {
            main: palette.dark.primary.main,
            light: palette.dark.secondary.light,
          },
          secondary: {
            main: palette.dark.secondary.main,
            light: palette.dark.secondary.light,
          },
          success: {
            main: palette.dark.success.main,
          },
          background: {
            paper: palette.light.third.main,
          },
          error: {
            main: palette.dark.error.main,
          },
          text: {
            primary: palette.dark.primary.main,
            disabled: palette.dark.third.main,
            secondary: palette.light.third.main,
          },
        }),
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    body1: {
      fontFamily: "Poppins , sans-serif",
    },
  },
});
