import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { Step1Interface } from '../interfaces/stepped/step1.interface';
import Toast from '../components/toast/Toast.jsx';
import PrimaryButton from '../components/forms/buttons/PrimaryButton';

export default function Step0({
  handleNext,
  activeStep,
  steps
}: Step1Interface) {
  const [inputError, setInputError] = useState(false);
  const theme = useTheme();
  const [accept, setAccept] = useState(0);

  const handleAcceptTerms = (e: any) => {
    setAccept(e.target.value);
  };
  const handleSubmit = () => {
    if (accept === 0) {
      setInputError(true);
    } else {
      setInputError(false);
      handleNext();
    }
  };
  return (
    <Paper sx={{ padding: 5, margin: 1, marginBottom: '10%' }}>
      <Grid sx={{ textAlign: 'justify', marginLeft: 2, paddingLeft: 2 }}>
        <Typography
          fontSize={28}
          fontWeight={500}
          color={theme.palette.primary.main}
        >
          Terms and conditions of use
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
        <Typography color={theme.palette.secondary.main} fontSize={18}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
        <br />
        <Typography color={theme.palette.secondary.main} fontSize={18}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
        <br />
        <Typography color={theme.palette.secondary.main} fontSize={18}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
        <br />
        <Typography color={theme.palette.secondary.main} fontSize={18}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
        <br />
        <Typography color={theme.palette.secondary.main} fontSize={18}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ margin: 2, padding: 2, color: theme.palette.secondary.main }}
      >
        <FormControlLabel
          control={
            <Checkbox
              value={1}
              name="radio-buttons"
              sx={{
                color: theme.palette.secondary.main
              }}
              onChange={(e) => handleAcceptTerms(e)}
            />
          }
          label="Accept the terms and conditions of data use to continue"
        />
        {inputError && (
          <Toast
            error="Accept terms and conditions of use."
            success={undefined}
            warning={undefined}
            info={undefined}
          />
        )}
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: 2,
          padding: 2
        }}
      >
        <PrimaryButton
          title={activeStep === steps.length - 1 ? 'Submit' : 'Next'}
          variant="outlined"
          fontSize={16}
          fontWeight={500}
          onPress={handleSubmit}
          style={{
            width: '20%',
            height: 40,
            borderRadius: 1,
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.main,
            ':hover': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.light
            }
          }}
        />
      </Grid>
    </Paper>
  );
}
