import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/forms/buttons/PrimaryButton";
import { useResponsiveQueries } from "../../../config/useResponsiveQueries";
import Title from "../../../components/title/Title";

export default function HomeOurCompanyContent() {
  const { mobileMaxWidth } = useResponsiveQueries();
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          alignSelf: "stretch",
        }}
      >
        <Grid
          sx={{
            textAlign: "left",
            margin: 1,
            width: "100%",
            mt: 4,
          }}
        >
          <Title
            title={" About Our Company".toUpperCase()}
            fontSize={mobileMaxWidth ? 18 : 20}
            fontWeight={600}
            style={{}}
          />
          <br />
            <Typography
            fontSize={24}
            fontWeight={700}
            color={theme.palette.primary.dark}
            >
            Delivering Top-Quality IT Services to Elevate Your Business
            </Typography>
          <br />
          <Typography
            fontSize={16}
            fontWeight={400}
            color={theme.palette.primary.dark}
          >
            At our company, we specialize in delivering a wide range of IT services tailored to meet the unique needs of your business. Our team of experts is dedicated to providing innovative solutions that drive efficiency, enhance productivity, and foster growth. Whether you're looking to streamline your operations, implement new technologies, or improve your business processes, we have the expertise and experience to help you achieve your goals.
          </Typography>
        </Grid>
        <Grid
          sx={{
            mt: 1,
            bgcolor: "transparent",
            width: "100%",
            maxWidth: 495,
          }}
        >
          <PrimaryButton
            title="Learn More"
            variant="outlined"
            fontSize={16}
            fontWeight={500}
            onPress={() => navigate("/service")}
            style={{
              width: mobileMaxWidth ? "100%" : 150,
              mr: 2,
              mt: 2,
              color: theme.palette.primary.light,
              backgroundColor: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              "&:hover": {
                color: theme.palette.primary.light,
                backgroundColor: theme.palette.background.paper,
                borderColor: theme.palette.background.paper,
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
