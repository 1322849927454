import React from "react";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// import Blog from '../../layouts/blog/Blog';
// import PictureMobileStepper from '../../components/pictureMobileStepper/PictureMobileStepper';
// import { imageBlog } from '../../constants/data';
import BlogSection from "../../layouts/blog/Blog";

export default function BlogScreen() {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        width: "100%",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.light,
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: 1500,
          justifyContent: "center",
          alignItems: "center",
          marginTop: "12%",
        }}
      >
        {/* <PictureMobileStepper pictures={imageBlog} /> */}
        <Grid item container sx={{ justifyContent: "center" }} xs={12}>
          <BlogSection />
        </Grid>
      </Grid>
    </Grid>
  );
}
