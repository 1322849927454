import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { WEB_MAX_WIDTH } from "../../../constants/mediaQueryConstants";
import { useResponsiveQueries } from "../../../config/useResponsiveQueries";

const data = [
  { value: "7 +", label: "Years Helping Business" },
  { value: "19 +", label: "Working Employees" },
  { value: "83 +", label: "Complete Projects" },
  { value: "126 +", label: "Happy Customers" },
];

export default function Banner1() {
  const theme = useTheme();
  const { mobileMaxWidth } = useResponsiveQueries();

  return (
    <Grid
      className="bandeau-image1"
      container
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        px: 4,
      }}
    >
      <Grid
        sx={{
          width: "100%",
          maxWidth: WEB_MAX_WIDTH,
          flexDirection: "column",
        }}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: mobileMaxWidth ? "column" : "row", // Vertical on mobile
            gap: mobileMaxWidth ? 2 : 0, // Add spacing between items on mobile
          }}
        >
          {data.map((item, index) => (
            <Grid
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mb: mobileMaxWidth ? 2 : 0, // Add margin for vertical spacing
              }}
            >
              <Typography
                fontSize={mobileMaxWidth ? 24 : 28} // Adjust font size for mobile
                sx={{ mt: 1 }}
                fontWeight={700}
                color={theme.palette.primary.light}
              >
                {item.value}
              </Typography>
              <Typography
                fontSize={mobileMaxWidth ? 14 : 16} // Adjust font size for mobile
                fontWeight={400}
                color={theme.palette.primary.light}
                textAlign={"center"}
              >
                {item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
