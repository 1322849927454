import React, { useState } from "react";
import {
  Grid,
  InputAdornment,
  TextField,
  Alert,
  Paper,
  Typography,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import PrimaryButton from "../../components/forms/buttons/PrimaryButton";

const darkImages = require("../../assets/Enter OTP-amico (1).png");
const lightImages = require("../../assets/Enter OTP-amico.png");

export default function SetPassword() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [authError, setAuthError] = useState(false);

  const handleUsername = (event: any) => {
    const tmp_username = event.target.value;
    setUsername(tmp_username);
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    if (username) {
      try {
      } catch (error) {
        // console.log(
        //   '❌ Unable to send email for verification. Please enter a valid username.',
        //   error
        // );
        setAuthError(true);
      }
    } else {
      // console.log('action');
    }
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{
        backgroundColor: theme.palette.primary.light,
        flexDirection: { xs: "column-reverse", md: "row" },
        flexWrap: { xs: "wrap", md: "nowrap" },
        paddingTop: "118px",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{ margin: 2, order: { xs: 2, md: 2 } }}
        className="about-image"
      >
        <Grid container justifyContent="center">
          {theme.palette.mode === "dark" ? (
            <img src={darkImages} alt="image" style={{ width: "80%" }} />
          ) : (
            <img src={lightImages} alt="image" style={{ width: "80%" }} />
          )}
        </Grid>
      </Grid>
      <Paper
        sx={{
          width: { xs: "100%", md: "42%" },
          backgroundColor: theme.palette.primary.light,
          margin: {
            xs: 0, // Aucune marge pour les écrans de petite taille
            md: 2, // Marge de 2 pour les écrans de taille moyenne et plus grands
          },
        }}
      >
        <Grid item xs={12} sx={{ marginTop: 5 }}>
          <Grid
            item
            xs={12}
            sx={{
              margin: { xs: 2, md: 4 },
              textAlign: "center",
            }}
          >
            <Typography
              fontSize={28}
              fontWeight={500}
              color={theme.palette.primary.main}
            >
              Set Password
            </Typography>
          </Grid>

          {authError && (
            <Alert severity="error" sx={{ margin: 2, padding: 1 }}>
              Oops an error occured please try again!
            </Alert>
          )}
          <Grid
            item
            container
            direction="row"
            xs={12}
            justifyContent={"center"}
          >
            <Grid item xs={12} md={6} sx={{ margin: 2, padding: 1 }}>
              <TextField
                label="Username"
                placeholder="Username"
                value={username}
                fullWidth
                required
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleUsername(e)}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ margin: { xs: 2, md: 4 }, textAlign: "center" }}
          >
            <PrimaryButton
              title="SUBMIT"
              variant="outlined"
              fontSize={16}
              fontWeight={500}
              onPress={submitForm}
              style={{
                width: "40%",
                height: 40,
                borderRadius: 1,
                color: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.main,
                ":hover": {
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.light,
                },
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
