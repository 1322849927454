import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import XIcon from "@mui/icons-material/X";
import { Facebook, LinkedIn } from "@mui/icons-material"; // Importer les icônes des réseaux sociaux depuis Material UI
import colors from "../../config/colors";

type profileCardInterface = {
  image: string;
  title: string;
  profile: string;
  twitterUri: string;
  linkedinUri: string;
  facebookUri: string;
};

export default function ProfileCard({
  image,
  title,
  profile,
  twitterUri,
  facebookUri,
  linkedinUri,
}: profileCardInterface) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <Card
      sx={{
        width: 250,
        borderRadius: 2,
        overflow: "hidden",
        position: "relative",
        "&:hover .hover-overlay": {
          opacity: 1,
        },
        m: 2,
        backgroundColor: colors.white,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Image et effet de survol */}
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{
          width: "100%",
          height: "300px",
          transition: "opacity 0.3s",
          opacity: isHovered ? 0.5 : 1, // Réduire l'opacité en hover
        }}
      />

      {/* Overlay avec les icônes des réseaux sociaux */}
      <Box
        className="hover-overlay"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: 0, // Caché par défaut
          transition: "opacity 0.3s",
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Fond semi-transparent pour l'effet de hover
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Facebook
              onClick={() => window.open(facebookUri, "_blank")}
              sx={{ color: colors.white, fontSize: 30, cursor: "pointer" }}
            />
          </Grid>
          <Grid item>
            <XIcon
              onClick={() => window.open(twitterUri, "_blank")}
              sx={{ color: colors.white, fontSize: 30, cursor: "pointer" }}
            />
          </Grid>
          <Grid item>
            <LinkedIn
              onClick={() => window.open(linkedinUri, "_blank")}
              sx={{ color: colors.white, fontSize: 30, cursor: "pointer" }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Contenu texte */}
      <CardContent
        sx={{ textAlign: "center", mt: 2, background: colors.white }}
      >
        <Typography variant="body2" fontWeight={700} color="textSecondary">
          {title}
        </Typography>
        <Typography variant="body2">{profile}</Typography>
      </CardContent>
    </Card>
  );
}
