import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { countriesCity } from '../../../constants/countries';
import CitySelectInterface from '../../../interfaces/forms/citySelect.interface';

const dataCity = require('../../../constants/csvjson.json');

interface Country {
  country: string;
  city: string;
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function CitySelected({
  countryName,
  width,
  style,
  setCitySelected
}: CitySelectInterface) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Country[]>([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;
    const city = countriesCity(countryName || 'Cameroon', dataCity);

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active) {
        setOptions([...city]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={style || { width: width || 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.city === value.city}
      getOptionLabel={(option) => {
        setCitySelected(option.city);
        return option.city;
      }}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="City"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}
