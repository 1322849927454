import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

import Stepped from '../../layouts/Stepped';

export default function AddCompany() {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        width: '100%',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.light
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: 1500,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Grid
          sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
          container
          // p={2}
          marginTop="10%"
          justifyContent="center"
        >
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }}>
            <Typography
              fontSize={28}
              fontWeight={500}
              color={theme.palette.text.secondary}
            >
              Create your business on Letservu
            </Typography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            margin: 5,
            borderWidth: 2,
            px: { xs: 2, md: 5 },
            pb: { xs: 2, md: 3 }
          }}
          container
          p={2}
          margin={2}
          justifyContent="center"
        >
          <Stepped />
        </Grid>
      </Grid>
    </Grid>
  );
}
