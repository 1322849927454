import axios from 'axios';
import { BASE_URL } from '../constants/base_url';
import { CompanyAddressInterface } from '../interfaces/companyCreation/companyAddress.interface';
import { CompanyInfoInterface } from '../interfaces/companyCreation/companyInfo.interface';
import { EmployerAddressInterface } from '../interfaces/companyCreation/employerAddress.interface';
import { EmployeeInfoInterface } from '../interfaces/companyCreation/employerInfo.interface';

// eslint-disable-next-line consistent-return
async function addCompany(
  payMethod: string[],
  companyInfoProps: CompanyInfoInterface,
  address: CompanyAddressInterface,
  userData: EmployeeInfoInterface,
  employeAdress: EmployerAddressInterface
): Promise<any | undefined> {
  try {
    let companyCreated = {};

    const {
      companyName,
      email,
      numberOfLocations,
      type,
      servicePlan,
      // paymentMethod,
      companyFormation
    } = companyInfoProps;

    const userInfo: any = userData;
    userInfo.address = employeAdress;

    userInfo.phoneNumber = `+${address.countryCode}${employeAdress.phoneNumber}`;
    userInfo.address.phoneNumber = `+${address.countryCode}${address.phoneNumber}`;

    // eslint-disable-next-line consistent-return
    const res = (pay: string[]) => {
      if (pay.length === 1) {
        return [pay[0]];
      }
      if (pay.length === 2) {
        return [pay[0], pay[1]];
      }
      if (pay.length === 3) {
        return [pay[0], pay[1], pay[2]];
      }
      return [];
    };
    const pay = res(payMethod);
    const data = JSON.stringify({
      query: `mutation ($address: JSON!, $userInfo: JSON!, $pay:[String]) {
      companyInfoCreateOne(
        companyName: "${companyName}",
        email: "${email}",
        numberOfLocations: ${numberOfLocations},
        type: "${type}",
        servicePlan: "${servicePlan}",
        companyFormation: "${companyFormation}",
        phoneNumber: "+${address.countryCode}${address.phoneNumber}",
        address: $address,
        userInfo: $userInfo,
        paymentMethod: $pay,
      ) 
    }`,
      variables: { address, userInfo, pay }
    });
    const config = {
      method: 'post',
      url: `${BASE_URL}/graphql`,
      headers: {
        'Content-Type': 'application/json'
      },
      data
    };

    companyCreated = await axios(config).then((response: any) => {
      if (response?.data?.errors?.length > 0) {
        return {
          error: response?.data?.errors,
          data: {}
        };
      }
      return {
        error: {},
        data: response
      };
    });
    return companyCreated;
  } catch (error) {
    return error;
  }
}

export { addCompany };
