import React from 'react';
import { Grid, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

import { useTheme } from '@mui/material/styles';
import ComponentCard from '../../components/card/ComponentCard';

const News = () => {
  const theme = useTheme();

  return (
    <Grid container sx={{ width: '100%', justifyContent: 'center' }}>
      <Grid
        sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
        container
        // p={2}
        marginTop="12%"
        justifyContent="center"
      >
        <Grid sx={{ flexDirection: 'column', textAlign: 'center' }}>
          <Typography
            fontSize={28}
            fontWeight={500}
            color={theme.palette.primary.main}
          >
            NEWS & EVENTS
          </Typography>
          <CircleIcon
            sx={{ color: theme.palette.primary.main, marginTop: 2 }}
          />
        </Grid>
      </Grid>
      <Grid
        sx={{ px: { xs: 4, md: 5 }, pb: { xs: 4, md: 5 } }}
        container
        p={2}
        justifyContent="center"
      >
        <Grid sx={{ margin: 1 }}>
          <ComponentCard
            title={'Nouvelle annonce'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut  adipiscing elit, sed do eiusmod tempor incididunt ' +
              'ut adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            }
            style={undefined}
            picture={undefined}
            textPreviewLength={250}
            width={undefined}
            height={undefined}
            pictureHeight={undefined}
            pictureWidth={undefined}
            color={undefined}
            noHover={true}
            noButton={undefined}
            href={'/contact'}
          />
        </Grid>
        <Grid sx={{ margin: 1 }}>
          <ComponentCard
            title={'Nouvelle annonce'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut  adipiscing elit, sed do eiusmod tempor incididunt ' +
              'ut adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            }
            style={undefined}
            picture={undefined}
            textPreviewLength={250}
            width={undefined}
            height={undefined}
            pictureHeight={undefined}
            pictureWidth={undefined}
            color={undefined}
            noHover={true}
            noButton={undefined}
            href={'/contact'}
          />
        </Grid>
        <Grid sx={{ margin: 1 }}>
          <ComponentCard
            title={'Nouvelle annonce'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut  adipiscing elit, sed do eiusmod tempor incididunt ' +
              'ut adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            }
            style={undefined}
            picture={undefined}
            textPreviewLength={250}
            width={undefined}
            height={undefined}
            pictureHeight={undefined}
            pictureWidth={undefined}
            color={undefined}
            noHover={true}
            noButton={undefined}
            href={'/contact'}
          />
        </Grid>
        <Grid sx={{ margin: 1 }}>
          <ComponentCard
            title={'Nouvelle annonce'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut  adipiscing elit, sed do eiusmod tempor incididunt ' +
              'ut adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            }
            style={undefined}
            picture={undefined}
            textPreviewLength={250}
            width={undefined}
            height={undefined}
            pictureHeight={undefined}
            pictureWidth={undefined}
            color={undefined}
            noHover={true}
            noButton={undefined}
            href={'/contact'}
          />
        </Grid>
        <Grid sx={{ margin: 1 }}>
          <ComponentCard
            title={'Nouvelle annonce'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut  adipiscing elit, sed do eiusmod tempor incididunt ' +
              'ut adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            }
            style={undefined}
            picture={undefined}
            textPreviewLength={250}
            width={undefined}
            height={undefined}
            pictureHeight={undefined}
            pictureWidth={undefined}
            color={undefined}
            noHover={true}
            noButton={undefined}
            href={'/contact'}
          />
        </Grid>
        <Grid sx={{ margin: 1 }}>
          <ComponentCard
            title={'Nouvelle annonce'}
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut  adipiscing elit, sed do eiusmod tempor incididunt ' +
              'ut adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat'
            }
            style={undefined}
            picture={undefined}
            textPreviewLength={250}
            width={undefined}
            height={undefined}
            pictureHeight={undefined}
            pictureWidth={undefined}
            color={undefined}
            noHover={true}
            noButton={undefined}
            href={'/contact'}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default News;
