export const address = "Houston, Texas, USA";
export const phoneNumber = "+1(650)-203-6699";
export const email = "customerservice@letservu.com";
export const lsuLink = "https://www.letservu.com/contactus";

export const Pages = [
  { name: "Home", link: "/" },
  { name: "About Us", link: "/about" },
  { name: "Our Services", link: "/services" },
  { name: "Contact Us", link: "/contact" },
  { name: "Blogs", link: "/blogs" },
];

const homeImage1 = require("../assets/luke-chesser-JKUTrJ4vK00-unsplash.jpg");
const homeImage2 = require("../assets/carlos-muza-hpjSkU2UYSU-unsplash.jpg");
const homeImage3 = require("../assets/stephen-dawson-qwtCeJ5cLYs-unsplash.jpg");
const homeImage4 = require("../assets/jaffer-nizami-ytZ2SJvNAxs-unsplash.jpg");

export const imageHome = [
  {
    label: "We bring your projects to life.",
    imgPath: homeImage1,
  },
  {
    label: "We support you in your digital transformation project.",
    imgPath: homeImage2,
  },
  {
    label:
      "An experienced team, passionate about new technologies, at your service.",
    imgPath: homeImage3,
  },
  {
    label: "We support you in your digital transformation project.",
    imgPath: homeImage4,
  },
];

const lightImages = require("../assets/Computer login-rafiki.png");

export const servicesCard = [
  {
    title: "AI Integration",
    description:
      "We provide AI solutions to improve business efficiencies and drive innovation.",
    image: lightImages,
  },
  {
    title: "Software Development",
    description:
      "Our team specializes in developing custom software tailored to meet your business needs.",
    image: lightImages,
  },
  {
    title: "Security Solutions",
    description:
      "Our security solutions are designed to protect your digital assets and ensure the integrity of your IT infrastructure.",
    image: lightImages,
  },
  {
    title: "Cloud Services",
    description:
      "We provide cloud solutions to help you scale your business and improve operational efficiency.",
    image: lightImages,
  },
];

export const footerData =
  "is a company specialized in the design and implementation of IT solutions. As such, we provide solutions for your IT projects" +
  ", from audit to implementation. Our aim is to enable companies to make the most of the possibilities offered by their IT systems" +
  ", while minimizing the costs associated with their implementation.";

export const services = [
  { name: "AI Integration", link: "/service-1" },
  { name: "Software Development", link: "/service-2" },
  { name: "Security Solutions", link: "/service-3" },
  { name: "Cloud Services", link: "/service-4" },
  { name: "Apps Developement", link: "/service-5" },
];
