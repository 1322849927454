import React from "react";
import { Grid } from "@mui/material";
import HomeBlogLayout from "../home/blogs/HomeBlogLayout";

const BlogSection = () => {
  return (
    <Grid
      sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
      container
      p={2}
      justifyContent="center"
    >
      <HomeBlogLayout />
    </Grid>
  );
};

export default BlogSection;
