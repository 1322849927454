/* eslint-disable object-curly-newline */
import React, { useContext, useState } from 'react';
import {
  Grid,
  Alert,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
  Box,
  useTheme,
  Typography
} from '@mui/material';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ApartmentIcon from '@mui/icons-material/Apartment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import EmailIcon from '@mui/icons-material/Email';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Step1Interface } from '../interfaces/stepped/step1.interface';
import {
  getLocalStorageCompanyInfo,
  setInLocalStorageCompanyInfo
} from '../config/localStorage';
import { CompanyInfoInterface } from '../interfaces/companyCreation/companyInfo.interface';
import PrimaryButton from '../components/forms/buttons/PrimaryButton';
import TextInput from '../components/forms/textField/TextInput';
import AppContext from '../config/appContext';

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('company name is required'),
  companyEmail: Yup.string().email().required('company email is required'),
  numberOfLocations: Yup.number()
    .required('number of locations is required')
    .min(1, 'Min is 1')
    .max(50, 'Max is 50')
});

export default function Step1({
  handleNext,
  activeStep,
  handleBack,
  steps
}: Step1Interface) {
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const { payMethod, setPayMethod } = useContext(AppContext);
  const CompanyInfo = getLocalStorageCompanyInfo();
  const [inputError, setInputError] = useState(false);
  const [servicePlan, setServicePlan] = useState(
    CompanyInfo?.servicePlan ? CompanyInfo?.servicePlan : ''
  );

  const [companyFormation, setCompanyFormation] = useState(
    CompanyInfo?.companyFormation ? CompanyInfo?.companyFormation : ''
  );

  const handleServicePlan = (event: any) => {
    const tmp_servicePlan = event.target.value;
    setServicePlan(tmp_servicePlan);
  };

  const handleCompanyFormation = (event: any) => {
    const tmp_companyFormation = event.target.value;
    setCompanyFormation(tmp_companyFormation);
  };

  const save = (values: any) => {
    const data: CompanyInfoInterface = {
      companyName: values.companyName,
      email: values.companyEmail,
      // paymentMethod: values.paymentMethod,
      numberOfLocations: Number(values.numberOfLocations),
      servicePlan,
      type: '',
      companyFormation
    };
    // console.log(save);
    setInputError(false);
    setInLocalStorageCompanyInfo(data);
    handleNext();
  };

  const handlePayMethod = (card: boolean, cash: boolean, electro: boolean) => {
    const res = [];
    if (card) {
      res.push('CARD');
    }
    if (cash) {
      res.push('CASH');
    }
    if (electro) {
      res.push('ELECTRONIC');
    }
    setPayMethod(res);
    // console.log(payMethod);
    return res;
  };

  return (
    <Paper sx={{ padding: 5, margin: 1, marginBottom: '10%' }}>
      <Grid item sx={{ margin: 2 }}>
        {inputError && (
          <Alert severity="error" sx={{ margin: 2 }}>
            Please enter all required information correctly.
          </Alert>
        )}
      </Grid>
      <Formik
        initialValues={{
          companyName: CompanyInfo?.companyName ? CompanyInfo?.companyName : '',
          companyEmail: CompanyInfo?.email ? CompanyInfo?.email : '',
          paymentMethod: [''],
          numberOfLocations: CompanyInfo?.numberOfLocations
            ? Number(CompanyInfo?.numberOfLocations)
            : 1,
          CARD: false,
          CASH: false,
          ELECTRONIC: false
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // eslint-disable-next-line no-param-reassign
          values.paymentMethod = handlePayMethod(
            values.CARD,
            values.CASH,
            values.ELECTRONIC
          );
          save(values);
        }}
      >
        {({
          handleChange,
          // submitForm,
          handleSubmit,
          values,
          errors,
          touched
        }) => (
          <Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Company Name"
                placeholder="Company Name"
                value={values.companyName}
                fullWidth
                name="companyName"
                required
                error={
                  errors.companyName && touched.companyName
                    ? errors.companyName
                    : ''
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <AddBusinessIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleChange('companyName')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Company Email"
                placeholder="Company Email"
                value={values.companyEmail}
                fullWidth
                name="companyEmail"
                required
                type="email"
                error={
                  errors.companyEmail && touched.companyEmail
                    ? errors.companyEmail
                    : ''
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <EmailIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleChange('companyEmail')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <Grid
                item
                xs={12}
                sx={{
                  '--Grid-borderWidth': '1px',
                  borderTop: 'var(--Grid-borderWidth) solid',
                  borderLeft: 'var(--Grid-borderWidth) solid',
                  borderRight: 'var(--Grid-borderWidth) solid',
                  borderBottom: 'var(--Grid-borderWidth) solid',
                  borderColor: 'divider'
                }}
              >
                <Grid
                  sx={{
                    px: { xs: 2, md: 7 },
                    pb: { xs: 2, md: 3 },
                    textAlign: 'justify',
                    marginTop: 5
                  }}
                >
                  <Typography>Payment Method</Typography>
                </Grid>
                <Grid
                  sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
                  container
                  justifyContent="space-between"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange('CASH')}
                          id={'CashId'}
                          value={!!values.CASH}
                          name={'CASH'}
                        />
                      }
                      label="CASH"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={'CardId'}
                          onChange={handleChange('CARD')}
                          value={!!values.CARD}
                          name={'CARD'}
                        />
                      }
                      label="CARD"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={'ElectronicId'}
                          onChange={handleChange('ELECTRONIC')}
                          value={!!values.ELECTRONIC}
                          name={'ELECTRONIC'}
                        />
                      }
                      label="ELECTRONIC"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-readonly-label">
                  Service Plan
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={servicePlan}
                  label="Service Plan"
                  sx={{ color: theme.palette.primary.main }}
                  onChange={(e) => handleServicePlan(e)}
                >
                  <MenuItem value={'FREE'}>FREE</MenuItem>
                  <MenuItem value={'GOLD'}>GOLD</MenuItem>
                  <MenuItem value={'LUXE'}>LUXE</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-readonly-label">
                  Company Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={companyFormation}
                  sx={{ color: theme.palette.primary.main }}
                  label="Company Type"
                  onChange={(e) => handleCompanyFormation(e)}
                >
                  <MenuItem value={'INDIVIDUAL'}>INDIVIDUAL</MenuItem>
                  <MenuItem value={'BUSINESS'}>BUSINESS</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Number Of Locations"
                placeholder="Number Of Locations"
                value={values.numberOfLocations}
                type="number"
                required
                fullWidth
                error={
                  errors.numberOfLocations && touched.numberOfLocations
                    ? errors.numberOfLocations
                    : ''
                }
                name="numberOfLocations"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <ApartmentIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleChange('numberOfLocations')}
              />
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: 2,
                padding: 2
              }}
            >
              <PrimaryButton
                title="Back"
                variant="outlined"
                fontSize={16}
                fontWeight={500}
                disabled={activeStep === 0}
                onPress={handleBack}
                style={{
                  width: '20%',
                  height: 40,
                  borderRadius: 1,
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.light,
                  ':hover': {
                    color: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.main
                  }
                }}
              />
              <Box sx={{ flex: '1 1 auto' }} />
              <PrimaryButton
                title={activeStep === steps.length - 1 ? 'Sign up now' : 'Next'}
                variant="outlined"
                fontSize={16}
                fontWeight={500}
                type={'button'}
                onPress={() => {
                  handleSubmit();
                }}
                disabled={
                  !values.companyEmail ||
                  !values.companyName ||
                  !values.numberOfLocations ||
                  (!values.CARD && !values.CASH && !values.ELECTRONIC)
                }
                style={{
                  width: '20%',
                  height: 40,
                  borderRadius: 1,
                  color: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.main,
                  ':hover': {
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.light
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </Paper>
  );
}
