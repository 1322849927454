/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import {
  Grid,
  Alert,
  InputAdornment,
  Box,
  Paper,
  useTheme
} from '@mui/material';
import EmailOutlined from '@mui/icons-material/Email';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DialpadIcon from '@mui/icons-material/Dialpad';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  getLocalStorageEmployerInfo,
  setInLocalStorageEmployerInfo
} from '../config/localStorage';
import { Step3Interface } from '../interfaces/stepped/step3.interface';
import { EmployeeInfoInterface } from '../interfaces/companyCreation/employerInfo.interface';
import PrimaryButton from '../components/forms/buttons/PrimaryButton';
import TextInput from '../components/forms/textField/TextInput';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('firstName is required'),
  lastName: Yup.string().required('lastName is required'),
  dateOfBirth: Yup.string().required('dateOfBirth is required'),
  userName: Yup.string().required('userName is required'),
  email: Yup.string().email().required('email address is required'),
  pin: Yup.string()
    .required('pin is required')
    .matches(/^[0-9]{6,6}$/, 'Is not in correct format, pin has 6 digits')
});

export default function Step3({
  handleNext,
  activeStep,
  handleBack,
  steps
}: Step3Interface) {
  const theme = useTheme();

  const employeInfo = getLocalStorageEmployerInfo();
  const [inputError, setInputError] = useState(false);

  const save = (values: any) => {
    const data: EmployeeInfoInterface = {
      firstName: values.firstName,
      email: values.email,
      userName: values.userName,
      pin: Number(values.pin),
      lastName: values.lastName,
      // eslint-disable-next-line object-shorthand
      dateOfBirth: values.dateOfBirth
    };
    // console.log(data);
    setInputError(false);
    setInLocalStorageEmployerInfo(data);
    handleNext();
  };
  return (
    <Paper sx={{ padding: 5, margin: 1, marginBottom: '10%' }}>
      <Formik
        initialValues={{
          firstName: employeInfo?.firstName ? employeInfo?.firstName : '',
          lastName: employeInfo?.lastName ? employeInfo?.lastName : '',
          dateOfBirth: employeInfo?.dateOfBirth
            ? employeInfo?.dateOfBirth
            : new Date(),
          userName: employeInfo?.userName ? employeInfo?.userName : '',
          email: employeInfo?.email ? employeInfo?.email : '',
          pin: employeInfo?.pin ? employeInfo?.pin : ''
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          save(values);
        }}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <Grid>
            <Grid item sx={{ margin: 2 }}>
              {inputError && (
                <Alert severity="error" sx={{ margin: 2 }}>
                  Please enter all required information correctly.
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="FirstName"
                placeholder="FirstName"
                value={values.firstName}
                name="firstName"
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <PermIdentityIcon />
                    </InputAdornment>
                  )
                }}
                error={
                  errors.firstName && touched.firstName ? errors.firstName : ''
                }
                onChange={handleChange('firstName')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="LastName"
                placeholder="LastName"
                name="lastName"
                value={values.lastName}
                type="text"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <PermIdentityIcon />
                    </InputAdornment>
                  )
                }}
                error={
                  errors.lastName && touched.lastName ? errors.lastName : ''
                }
                onChange={handleChange('lastName')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Email"
                placeholder="Email"
                value={values.email}
                fullWidth
                required
                type="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <EmailOutlined />
                    </InputAdornment>
                  )
                }}
                name={'email'}
                error={errors.email && touched.email ? errors.email : ''}
                onChange={handleChange('email')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="UserName"
                placeholder="UserName"
                value={values.userName}
                type="text"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <AccountCircle />
                    </InputAdornment>
                  )
                }}
                name={'userName'}
                error={
                  errors.userName && touched.userName ? errors.userName : ''
                }
                onChange={handleChange('userName')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Date Of Birth"
                placeholder="Date Of Birth"
                value={
                  typeof values.dateOfBirth === 'string'
                    ? values.dateOfBirth
                    : values.dateOfBirth?.toLocaleDateString()
                }
                type="date"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <CalendarMonthIcon />
                    </InputAdornment>
                  )
                }}
                name={'dateOfBirth'}
                error={
                  errors.dateOfBirth && touched.dateOfBirth
                    ? errors.dateOfBirth
                    : ''
                }
                onChange={handleChange('dateOfBirth')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Pin"
                placeholder="Pin"
                value={values.pin}
                type="number"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <DialpadIcon />
                    </InputAdornment>
                  )
                }}
                name={'pin'}
                error={errors.pin && touched.pin ? errors.pin : ''}
                onChange={handleChange('pin')}
              />
            </Grid>
            <Grid
              sx={{
                margin: 2,
                padding: 2,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <PrimaryButton
                title="Back"
                variant="outlined"
                fontSize={16}
                fontWeight={500}
                disabled={activeStep === 0}
                onPress={handleBack}
                style={{
                  width: '20%',
                  height: 40,
                  borderRadius: 1,
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.light,
                  ':hover': {
                    color: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.main
                  }
                }}
              />
              <Box sx={{ flex: '1 1 auto' }} />
              <PrimaryButton
                title={activeStep === steps.length - 1 ? 'Sign up now' : 'Next'}
                variant="outlined"
                fontSize={16}
                fontWeight={500}
                disabled={
                  !values.firstName ||
                  !values.lastName ||
                  !values.userName ||
                  !values.dateOfBirth ||
                  !values.email ||
                  !values.pin
                }
                onPress={handleSubmit}
                style={{
                  width: '20%',
                  height: 40,
                  borderRadius: 1,
                  color: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.main,
                  ':hover': {
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.light
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </Paper>
  );
}
