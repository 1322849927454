import { Grid } from "@mui/material";
import React from "react";
import { useResponsiveQueries } from "../../../config/useResponsiveQueries";
import { trushCompanyImage } from "../../../constants/home";

export default function HomeTrushUsImage() {
  const { tabletMaxWidth, mobileMaxWidth } = useResponsiveQueries();
  return (
    <Grid
      container
      sx={{
        display: "flex",
        width: "100%",
        padding: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignSelf: "stretch",
        }}
      >
        <img
          src={trushCompanyImage}
          alt="trushCompanyImage"
          style={{
            display: "flex",
            alignItems: "center",
            height: tabletMaxWidth ? (mobileMaxWidth ? 277 : 444) : 444,
            marginTop: 10,
          }}
        />
      </Grid>
    </Grid>
  );
}
