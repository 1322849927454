import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import countries from '../../../constants/countries';
import { CountryInterfaceInterface } from '../../../interfaces/forms/countrySelect.interface';

function CountrySelect({
  width,
  style,
  setCountryLabel,
  setCountrySelected
}: CountryInterfaceInterface) {
  return (
    <Autocomplete
      sx={style || { width: width || 300 }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => {
        setCountrySelected(option || '');
        setCountryLabel(option.label || '');
        return option.label;
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: false // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}

export default CountrySelect;
