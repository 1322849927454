
// import * as React from 'react';
// import { Typography } from '@mui/material';
// import Button from '@mui/material/Button';
// import { PrimaryButtonInterface } from '../../../interfaces/forms/primaryButton.interface';

// export default function PrimaryButton({
//   variant,
//   title,
//   size,
//   onPress,
//   fontSize,
//   fontWeight,
//   type,
//   style,
//   disabled,
//   startIcon
// }: PrimaryButtonInterface) {
//   return (
//     <Button
//       variant={variant}
//       disabled={disabled}
//       sx={style}
//       size={size}
//       onClick={onPress}
//       type={type}
//       startIcon={startIcon}
//     >
//       <Typography fontSize={fontSize} fontWeight={fontWeight}>
//         {title}
//       </Typography>
//     </Button>
//   );
// }

import * as React from 'react';
import { Typography, Button, useTheme } from "@mui/material";
import { PrimaryButtonInterface } from "../../../interfaces/forms/primaryButton.interface";


export default function PrimaryButton({
  variant,
  title,
  size,
  onPress,
  fontSize,
  color,
  bgcolor,
  borderRadius,
  fontWeight,
  type,
  style,
  width,
  height,
  disabled,
  startIcon,
  endIcon,
}: PrimaryButtonInterface) {
  const theme = useTheme();
  return (
    <Button
      fullWidth
      variant={variant}
      disabled={disabled}
      sx={{

        ...style,
        padding: '30px 25px', // Augmenter la taille du bouton
        margin: '10px', // Ajouter un espace autour du bouton
        border: '1px solid #ccc', // Ajouter un bord pour le cadre
        borderRadius: '8px', // Arrondir les coins
        boxShadow: '0px 4px 6px rgba(0, 0, 0, )', // Ajouter une ombre pour l'effet de cadre

        color: color || theme.palette.primary.light,
        backgroundColor: bgcolor || theme.palette.background.paper,
        borderColor: theme.palette.background.paper,
        width: width,
        height: height || 50,
        // borderRadius: borderRadius || 1,
        p: 2,
        "&:hover": {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light,
          borderColor: theme.palette.primary.light,
        },
        ...style,

      }}
      size={size}
      onClick={onPress}
      type={type}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      <Typography
        textTransform={"none"}
        lineHeight={1.5}
        fontSize={fontSize || 14}
        fontFamily={"Poppins"}
        fontWeight={fontWeight || 600}
        fontStyle={"normal"}
      >
        {title}
      </Typography>
    </Button>
  );
}
