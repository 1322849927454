/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  useTheme,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid } from "@mui/material";
import { Amplify } from "aws-amplify";
import "./style.css";
import AddCompany from "./screens/addCompany/AddCompany";
import Footer from "./components/footer/Footer";
import Home from "./screens/home/Home";
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import ResetPassword from "./screens/resetPassword/ResetPassword";
import SetPassword from "./screens/forgotPassword/SetPassword";
import ForgotPassword from "./screens/forgotPassword/ForgotPassword";
import Login from "./screens/login/Login";
import PageNotFound from "./screens/errors/PageNotFound";
import Loading from "./components/loading/Loading";
import AppContext from "./config/appContext";
import { getDesignTokens } from "./config/theme";
import { ColorModeContext } from "./config/color-context";
import ServerError from "./screens/errors/ServerError";
import AboutScreen from "./screens/about/AboutScreen";
import ServicesScreen from "./screens/services/ServicesScreen";
import NewScreen from "./screens/news/NewsScreen";
import ContactUsScreen from "./screens/contactUs/ContactUsScreen";
import BlogScreen from "./screens/blog/BlogScreen";
import config from "./aws-exports";

Amplify.configure({
  ...config,
});

function PageWithFooter() {
  const theme = useTheme();
  return (
    <Grid>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/company" element={<AddCompany />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/services" element={<ServicesScreen />} />
        <Route path="/new" element={<NewScreen />} />
        <Route path="/contact" element={<ContactUsScreen />} />
        <Route path="/blogs" element={<BlogScreen />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/setPassword" element={<SetPassword />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <Grid
        sx={{
          justifyContent: "center",
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <Footer />
      </Grid>
    </Grid>
  );
}

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: light)");
  const [mode, setMode] = useState("light");
  const [isLoading, setisLoading] = useState(false);
  const [payMethod, setPayMethod] = useState([""]);

  useEffect(() => {
    setMode(prefersDarkMode ? "light" : "light"); // dark
    // setMode(prefersDarkMode ? "light" : "dark"); // dark
  }, [prefersDarkMode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  let theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  theme = responsiveFontSizes(theme);
  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider
        value={{ isLoading, setisLoading, payMethod, setPayMethod }}
      >
        <ColorModeContext.Provider value={colorMode}>
          {!isLoading ? (
            <BrowserRouter>
              <Grid
                sx={{
                  overflowX: "hidden",
                  justifyContent: "center",
                  backgroundColor: theme.palette.primary.light,
                }}
              >
                <Sidebar />
                <Navbar />
              </Grid>
              <Routes>
                <Route path="/notFound" element={<PageNotFound />} />
                <Route path="/serverError" element={<ServerError />} />
                <Route path="/*" element={<PageWithFooter />} />
              </Routes>
            </BrowserRouter>
          ) : (
            <Loading loadingMsg={"Loading"} isLoading={true} />
          )}
        </ColorModeContext.Provider>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
