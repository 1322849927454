/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import {
  Grid,
  Alert,
  Box,
  InputAdornment,
  Paper,
  useTheme
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import Phone from '@mui/icons-material/Phone';
import DialpadIcon from '@mui/icons-material/Dialpad';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { CompanyAddressInterface } from '../interfaces/companyCreation/companyAddress.interface';
import {
  getLocalStorageCompanyAddress,
  setInLocalStorageCompanyAddress
} from '../config/localStorage';
import CountrySelect from '../components/forms/textField/CountrySelect';
import { Step2Interface } from '../interfaces/stepped/step2.interface';
import PrimaryButton from '../components/forms/buttons/PrimaryButton';
import CitySelected from '../components/forms/textField/CitySelected';
import TextInput from '../components/forms/textField/TextInput';

const validationSchema = Yup.object().shape({
  addressLine1: Yup.string().required('addressLine1 is required'),
  addressLine2: Yup.string().required('addressLine2 is required'),
  state: Yup.string().required('state is required'),
  phoneNumber: Yup.number().required('company phone is required'),
  zipCode: Yup.string()
    .required('zipCode is required')
    .matches(/^[0-9]{5,5}$/, 'Is not in correct format, zipCode has 5 digits')
});

export default function Step2({
  handleBack,
  handleNext,
  activeStep,
  steps
}: Step2Interface) {
  const theme = useTheme();

  const companyAddress = getLocalStorageCompanyAddress();
  const [countryObject, setCountryObject] = useState({
    code: '',
    label: companyAddress?.country ? companyAddress?.country : '',
    phone: companyAddress?.countryCode ? companyAddress?.countryCode : ''
  });

  const [city, setCity] = useState(
    companyAddress?.city ? companyAddress?.city : ''
  );

  const [inputError, setInputError] = useState(false);
  const [country, setCountry] = useState(
    companyAddress?.country ? companyAddress?.country : ''
  );

  const save = (values: any) => {
    const data: CompanyAddressInterface = {
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city,
      state: values.state,
      zipCode: values.zipCode,
      country,
      phoneNumber: values.phoneNumber,
      countryCode: countryObject.phone
    };
    // console.log(data);
    setInputError(false);
    setInLocalStorageCompanyAddress(data);
    handleNext();
  };

  return (
    <Paper sx={{ padding: 5, margin: 1, marginBottom: '10%' }}>
      <Grid item sx={{ margin: 2 }}>
        {inputError && (
          <Alert severity="error" sx={{ margin: 2 }}>
            Please enter all required information correctly.
          </Alert>
        )}
      </Grid>
      <Formik
        initialValues={{
          addressLine1: companyAddress?.addressLine1
            ? companyAddress?.addressLine1
            : '',
          addressLine2: companyAddress?.addressLine2
            ? companyAddress?.addressLine2
            : '',
          state: companyAddress?.state ? companyAddress?.state : '',
          zipCode: companyAddress?.zipCode ? companyAddress?.zipCode : '',
          phoneNumber: companyAddress?.phoneNumber
            ? companyAddress?.phoneNumber
            : ''
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          save(values);
        }}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <CountrySelect
                width={'100%'}
                countrySelected={countryObject}
                setCountryLabel={setCountry}
                setCountrySelected={setCountryObject}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="State"
                placeholder="State"
                required
                value={values.state}
                name="state"
                fullWidth
                type={'text'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <LanguageIcon />
                    </InputAdornment>
                  )
                }}
                error={errors.state && touched.state ? errors.state : ''}
                onChange={handleChange('state')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <CitySelected
                width={'100%'}
                countryName={country}
                citySelected={city}
                setCitySelected={setCity}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="AddressLine1"
                placeholder="AddressLine1"
                fullWidth
                required
                name="addressLine1"
                value={values.addressLine1}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <LocationOnIcon />
                    </InputAdornment>
                  )
                }}
                error={
                  errors.addressLine1 && touched.addressLine1
                    ? errors.addressLine1
                    : ''
                }
                onChange={handleChange('addressLine1')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="AddressLine2"
                placeholder="AddressLine2"
                fullWidth
                value={values.addressLine2}
                name="addressLine1"
                required
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <LocationOnIcon />
                    </InputAdornment>
                  )
                }}
                error={
                  errors.addressLine1 && touched.addressLine1
                    ? errors.addressLine1
                    : ''
                }
                onChange={handleChange('addressLine2')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Country Code"
                placeholder="Country Code"
                required
                value={`+${countryObject?.phone}`}
                disabled={true}
                fullWidth
                type={'text'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <DialpadIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Company Phone"
                placeholder="Company Phone"
                value={values.phoneNumber}
                type="number"
                required
                error={
                  errors.phoneNumber && touched.phoneNumber
                    ? errors.phoneNumber
                    : ''
                }
                fullWidth
                name="phoneNumber"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <Phone />
                    </InputAdornment>
                  )
                }}
                onChange={handleChange('phoneNumber')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Zip Code"
                placeholder="Zip Code"
                required
                name={'zipCode'}
                value={values.zipCode}
                fullWidth
                type={'text'}
                error={errors.zipCode && touched.zipCode ? errors.zipCode : ''}
                onChange={handleChange('zipCode')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <DialpadIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid
              sx={{
                margin: 2,
                padding: 2,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <PrimaryButton
                title="Back"
                variant="outlined"
                fontSize={16}
                fontWeight={500}
                disabled={activeStep === 0}
                onPress={handleBack}
                style={{
                  width: '20%',
                  height: 40,
                  borderRadius: 1,
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.light,
                  ':hover': {
                    color: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.main
                  }
                }}
              />
              <Box sx={{ flex: '1 1 auto' }} />
              <PrimaryButton
                title={activeStep === steps.length - 1 ? 'Sign up now' : 'Next'}
                variant="outlined"
                fontSize={16}
                fontWeight={500}
                disabled={
                  !values.addressLine1 ||
                  !values.phoneNumber ||
                  !values.addressLine2 ||
                  !values.state ||
                  !values.zipCode
                }
                onPress={handleSubmit}
                style={{
                  width: '20%',
                  height: 40,
                  borderRadius: 1,
                  color: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.main,
                  ':hover': {
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.light
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </Paper>
  );
}
