/* eslint-disable object-curly-newline */
import * as React from 'react';
import { Grid, Box, Alert, Button } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step0 from './Step0';
import Step5 from './Step5';

const steps = [
  'Terms and conditions of use',
  'Company information',
  'Company address',
  'Employer Information',
  'Employee address',
  'Final preview'
];

export default function Stepped() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step: any) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const stepped = (value: any) => {
    switch (value) {
      case 1:
        return (
          <Step0
            handleNext={handleNext}
            handleBack={handleBack}
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );
      case 2:
        return (
          <Step1
            handleNext={handleNext}
            handleBack={handleBack}
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );
      case 3:
        return (
          <Step2
            handleNext={handleNext}
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleBack={handleBack}
          />
        );
      case 4:
        return (
          <Step3
            handleNext={handleNext}
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleBack={handleBack}
          />
        );
      case 5:
        return (
          <Step4
            handleNext={handleNext}
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleBack={handleBack}
          />
        );
      case 6:
        return (
          <Step5
            handleNext={handleNext}
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleBack={handleBack}
          />
        );
      default:
        return (
          <Step0
            handleNext={handleNext}
            steps={steps}
            handleBack={handleBack}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        );
    }
  };

  return (
    <Grid
      sx={{
        marginLeft: 2,
        marginRight: 2,
        width: '80%',
        justifyContent: 'center'
      }}
    >
      <Stepper activeStep={activeStep} alternativeLabel={true}>
        {steps.map((label, index) => {
          const stepProps: any = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step
              key={label}
              {...stepProps}
              sx={{ marginTop: 1, marginBottom: 3 }}
            >
              <StepLabel error={false} {...labelProps}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Grid item sx={{ margin: 2 }}>
            <Alert severity="success" sx={{ margin: 2 }}>
              Company created successfully. You will receive a confirmation
              email
            </Alert>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button
              variant="contained"
              sx={{ marginRight: 4 }}
              size="large"
              onClick={handleReset}
            >
              {'Create a new company?'}
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>{stepped(activeStep + 1)}</React.Fragment>
      )}
    </Grid>
  );
}
