import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useResponsiveQueries } from "../../../config/useResponsiveQueries";
import { WEB_MAX_WIDTH } from "../../../constants/mediaQueryConstants";
import Title from "../../../components/title/Title";
import PrimaryButton from "../../../components/forms/buttons/PrimaryButton";
import { phoneNumber } from "../../../constants/data";

export default function Banner2() {
  const { tabletMaxWidth, mobileMaxWidth } = useResponsiveQueries();
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Grid
      className="bandeau-image"
      container
      sx={{
        width: "100%",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.dark,
      }}
    >
      <Grid
        sx={{ width: "100%", maxWidth: WEB_MAX_WIDTH, flexDirection: "column" }}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "65%",
              mt: 4,
            }}
          >
            <Title
              title={"Want to work with us?".toUpperCase()}
              fontSize={mobileMaxWidth ? 18 : 20}
              fontWeight={600}
              style={{}}
            />
            <Typography
              fontSize={28}
              sx={{ mt: 1 }}
              fontWeight={700}
              color={theme.palette.primary.light}
            >
              Digitally Transform & Grow Your Business
            </Typography>
            <br />
            <Typography
              fontSize={16}
              fontWeight={400}
              color={theme.palette.primary.light}
              textAlign={"center"}
            >
              Partner with us to leverage cutting-edge technology and innovative
              solutions that drive efficiency, enhance productivity, and foster
              growth. Our team of experts is dedicated to helping you achieve
              your business goals through AI integration, software development,
              security solutions, and cloud services. Let's work together to
              digitally transform and grow your business.
            </Typography>
            <Grid
              sx={{
                // display: "flex",
                textAlign: "center",
                my: 2,
                width: "100%",
              }}
            >
              <PrimaryButton
                title="Call now"
                variant="outlined"
                fontSize={16}
                fontWeight={500}
                onPress={() => {
                  window.location.href = `tel:${phoneNumber}`;
                }}
                style={{
                  width: tabletMaxWidth ? "100%" : 150,
                  mr: 2,
                  mt: 2,
                  color: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                  "&:hover": {
                    color: theme.palette.primary.light,
                    backgroundColor: theme.palette.background.paper,
                    borderColor: theme.palette.background.paper,
                  },
                }}
              />
              <PrimaryButton
                title="Contact Us"
                variant="outlined"
                fontSize={16}
                fontWeight={500}
                onPress={() => navigate("/contact")}
                style={{
                  width: tabletMaxWidth ? "100%" : 150,
                  mr: 2,
                  mt: 2,
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.light,
                  borderColor: theme.palette.primary.light,
                  "&:hover": {
                    color: theme.palette.primary.light,
                    backgroundColor: theme.palette.background.paper,
                    borderColor: theme.palette.background.paper,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
