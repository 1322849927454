import React from "react";
import Title from "../../../components/title/Title";
import { Grid, Typography } from "@mui/material";
import { useResponsiveQueries } from "../../../config/useResponsiveQueries";
import { WEB_MAX_WIDTH } from "../../../constants/mediaQueryConstants";
import ProfileCard from "../../../components/card/ProfileCard";

const avatarIm = require("../../../assets/undraw_avatar.png");
const loicImage = require("../../../assets/teams/loic_img.jpg");
const briceImage = require("../../../assets/teams/brice_img.jpg");
// Tableau de données pour les membres de l'équipe
const teamMembers = [
  {
    image: avatarIm,
    title: "Roby Nkwamo",
    profile: "Software Architect",
    twitterUri: "#",
    linkedinUri: "https://www.linkedin.com/in/roby-nkwamo-820274147",
    facebookUri: "#",
  },
  {
    image: avatarIm,
    title: "Hermann Ngamassi",
    profile: "Cybersecurity Expert",
    twitterUri: "#",
    linkedinUri: "https://www.linkedin.com/in/hermann-ngamassi-689625a9",
    facebookUri: "#",
  },
  {
    image: briceImage,
    title: "Brice Ngantou",
    profile: "Full Stack Developer",
    twitterUri: "",
    linkedinUri: "https://www.linkedin.com/in/brice-ngantou/",
    facebookUri: "",
  },
  {
    image: loicImage,
    title: "Loic MELI",
    profile: "Lead Data Engineer",
    twitterUri: "",
    linkedinUri: "https://www.linkedin.com/in/loic-meli-yamtcheu/",
    facebookUri: "",
  },
];

export default function HomeTeamLayout() {
  const { mobileMaxWidth, customMaxWidth } = useResponsiveQueries();

  return (
    <Grid
      container
      sx={{
        width: "100%",
        justifyContent: "center",
        padding: mobileMaxWidth ? "20px 10px" : "40px 20px",
      }}
    >
      <Grid container sx={{ maxWidth: WEB_MAX_WIDTH, flexDirection: "column" }}>
        {/* Section Title */}
        <Grid
          sx={{
            mb: 2,
            mt: 0,
            textAlign: "center",
            flexDirection: "column",
            padding: mobileMaxWidth ? "0px 10px" : "0px 40px",
          }}
        >
          <Title
            title="TEAM MEMBER"
            fontSize={mobileMaxWidth ? 18 : 20}
            fontWeight={600}
            style={{ margin: "0 0 10px 0" }}
          />
          <Typography
            lineHeight="normal"
            fontFamily="Poppins"
            fontSize={mobileMaxWidth ? 24 : 32}
            fontWeight={700}
          >
            Expert Team
          </Typography>
        </Grid>

        {/* Team Members Grid */}
        <Grid
          container
          spacing={4} // Ajout de l'espace entre les cartes
          justifyContent={customMaxWidth ? "center" : "flex-start"}
          sx={{
            mt: 2,
            padding: mobileMaxWidth ? "0px 10px" : "0px 40px",
          }}
        >
          {teamMembers.map((member, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ProfileCard
                image={member.image}
                title={member.title}
                profile={member.profile}
                twitterUri={member.twitterUri}
                linkedinUri={member.linkedinUri}
                facebookUri={member.facebookUri}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
