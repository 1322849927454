import React from "react";
import Title from "../../../components/title/Title";
import { Grid, Typography } from "@mui/material";
import { useResponsiveQueries } from "../../../config/useResponsiveQueries";
import { WEB_MAX_WIDTH } from "../../../constants/mediaQueryConstants";
import BlogCard from "../../../components/card/BlogCard";

const blogPosts = [
  {
    image: "https://media.mktg.workday.com/is/image/workday/how-employees-feel-ai-at-work?wid=1600&dpr=off&fit=fit,1&qlt=85,0&resMode=sharp2&op_usm=1.75,1,10,0",
    title: "How Do Employees Feel About AI at Work and What Can You Do?",
    description: "Empower your team to benefit from AI by understanding their perspective. A commissioned study conducted by Forrester Consulting on behalf of Workday, “A Human-Centered Approach to Al in the Workplace,” shares more.",
    author: "Aashna Kircher",
    date: "7 December 2023",
    link: "https://www.workday.com/en-us/pages/ai-at-work.html",
  },
  {
    image: "https://www.eitbiz.com/blog/wp-content/uploads/2024/03/Top-benefits-of-web-app-development-for-a-business.jpg",
    title: "Top Benefits of Web App Development for a Business?",
    description: "Web applications play a crucial role in modern business operations by enabling companies to empower their workforce, seamlessly engage with customers, and broaden their reach to a diverse audience.",
    author: "EitBiz ",
    date: "15 March 2024",
    link: "https://www.eitbiz.com/blog/top-benefits-of-web-app-development-for-a-business/",
  },
  {
    image: "https://www.nu.edu/wp-content/uploads/2022/09/Cybersecurity-PhD-TM.jpg?fit=1920%2C420",
    title: "What is Cybersecurity and Its Importance to Business?",
    description: "Are you considering a cybersecurity degree and wondering if it’s worth it? You’re not alone. In today’s interconnected world, cybersecurity is more important than ever.",
    author: "National University",
    date: "15 June 2024",
    link: "https://www.nu.edu/blog/what-is-cybersecurity",
  },
];

export default function HomeBlogLayout() {
  const { mobileMaxWidth, customMaxWidth } = useResponsiveQueries();
  return (
    <Grid
      container
      sx={{
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Grid container sx={{ maxWidth: WEB_MAX_WIDTH, flexDirection: "column" }}>
        <Grid
          sx={{
            mb: 2,
            mt: 0,
            textAlign: "center",
            flexDirection: "column",
            padding: mobileMaxWidth
              ? "0px var(--Spacing-spacing-3xl, 5px)"
              : "0px var(--Spacing-spacing-3xl, 40px)",
          }}
        >
          <Title
            title={"Blog & Article".toUpperCase()}
            fontSize={mobileMaxWidth ? 18 : 20}
            fontWeight={600}
            style={{ m: 2, my: 0 }}
          />
          <Typography
            lineHeight={"normal"}
            fontFamily={"Poppins"}
            fontSize={mobileMaxWidth ? 28 : 32}
            fontWeight={700}
          >
            Recent Blog
          </Typography>
        </Grid>
        <Grid
          container
          sx={{ maxWidth: WEB_MAX_WIDTH, flexDirection: "column" }}
        >
          {/* Blog Cards Grid */}
          <Grid
            container
            spacing={3} // Ajout de l'espace entre les cartes
            justifyContent={customMaxWidth ? "center" : "flex-start"}
            sx={{
              padding: mobileMaxWidth ? "0px 10px" : "0px 40px",
              mt: 2,
            }}
          >
            {blogPosts.map((post, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <BlogCard
                  image={post.image}
                  title={post.title}
                  description={post.description}
                  author={post.author}
                  date={post.date}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
