import React from "react";
import { Chip, Grid } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";
import { PhoneOutlined, EmailOutlined } from "@mui/icons-material";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import { useTheme } from "@mui/material/styles";
import { address, email, phoneNumber } from "../../constants/data";
import { useResponsiveQueries } from "../../config/useResponsiveQueries";

function Sidebar() {
  const theme = useTheme();
  const { mobileMaxWidth } = useResponsiveQueries();

  return (
    <Grid
      sx={{
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        maxHeight: { md: 140 },
        display: "flex",
        justifyContent: "center",
        zIndex: 10,
        position: "fixed",
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: 1500,
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
        spacing={2}
      >
        <Grid
          item
          container
          sx={{
            direction: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
          flexDirection={"row"}
        >
          <Grid
            item
            container
            sx={{
              direction: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              sx={{
                marginLeft: "10%",
              }}
            >
              {!mobileMaxWidth && (
                <Chip
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.light,
                    ":hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.light,
                    },
                  }}
                  icon={
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.primary.light,
                      }}
                    >
                      <PhoneOutlined
                        sx={{ color: theme.palette.primary.light, height: 15 }}
                      />
                    </Grid>
                  }
                  label={phoneNumber}
                  href={`tel:${phoneNumber}`}
                  component="a"
                  clickable
                />
              )}
              <Chip
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.light,
                  ":hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.light,
                  },
                }}
                icon={
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: theme.palette.primary.light,
                    }}
                  >
                    <EmailOutlined
                      sx={{ color: theme.palette.primary.light, height: 15 }}
                    />
                  </Grid>
                }
                label={email}
                href={`mailTo:${email}`}
                component="a"
                clickable
              />
              {!mobileMaxWidth && (
                <Chip
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.light,
                    ":hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.light,
                    },
                  }}
                  icon={
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.primary.light,
                      }}
                    >
                      <DirectionsIcon
                        sx={{ color: theme.palette.primary.light, height: 15 }}
                      />
                    </Grid>
                  }
                  label={address}
                  variant="filled"
                  href={`http://maps.google.com/?q=${address}`}
                  target="_blank"
                  component="a"
                  clickable
                />
              )}
            </Grid>
            {!mobileMaxWidth && (
              <Grid
                item
                sx={{
                  justifyContent: "right",
                  marginRight: "10%",
                  marginLeft: "10%",
                  display: "flex",
                  gap: 2,
                }}
              >
                <GoogleIcon
                  sx={{
                    color: theme.palette.primary.light,
                    fontSize: 20, // Augmenter la taille
                    padding: "2px",
                    borderRadius: "8px", // Arrondir les coins du cadre
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.main,
                    },
                  }}
                />
                <FacebookIcon
                  sx={{
                    color: theme.palette.primary.light,
                    fontSize: 20, // Augmenter la taille
                    padding: "2px",
                    borderRadius: "8px", // Arrondir les coins du cadre
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.main,
                    },
                  }}
                />
                <TwitterIcon
                  sx={{
                    color: theme.palette.primary.light,
                    fontSize: 20, // Augmenter la taille
                    padding: "2px",
                    borderRadius: "8px", // Arrondir les coins du cadre
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.main,
                    },
                  }}
                />
                <InstagramIcon
                  sx={{
                    color: theme.palette.primary.light,
                    fontSize: 20, // Augmenter la taille
                    padding: "2px",
                    borderRadius: "8px", // Arrondir les coins du cadre
                    cursor: "pointer",
                    ":hover": {
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Sidebar;
