import React from "react";
import Title from "../../../components/title/Title";
import { Grid, Typography, useTheme } from "@mui/material";
import { useResponsiveQueries } from "../../../config/useResponsiveQueries";

export default function HomeChooseUsContent() {
  const { mobileMaxWidth } = useResponsiveQueries();
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          alignSelf: "stretch",
        }}
      >
        <Grid
          sx={{
            textAlign: "left",
            margin: 1,
            width: "100%",
            mt: 4,
          }}
        >
          <Title
            title={"Why Choose Us?".toUpperCase()}
            fontSize={mobileMaxWidth ? 18 : 20}
            fontWeight={600}
            style={{}}
          />
          <br />
          <Typography
            fontSize={24}
            fontWeight={700}
            color={theme.palette.primary.dark}
          >
            Partner with us to take your business to the next level with our comprehensive IT solutions.
          </Typography>
          <br />
          <Typography
            fontSize={16}
            fontWeight={400}
            color={theme.palette.primary.dark}
          >
            Choosing us means partnering with a team of dedicated professionals who are committed to your success. 
            From software development to cloud services, we have the expertise and experience to deliver 
            innovative solutions that drive efficiency, enhance productivity, and foster growth. 
          </Typography>
        </Grid>
        <Grid sx={{ mt: 1 }}></Grid>
        <Grid
          sx={{
            mt: 1,
            bgcolor: "transparent",
            width: "100%",
            maxWidth: 495,
          }}
        ></Grid>
      </Grid>
    </Grid>
  );
}
