import React, { useState } from "react";
import {
  AppBar,
  Chip,
  Divider,
  Grid,
  Link,
  Toolbar,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { PhoneOutlined, Email } from "@mui/icons-material";
import DirectionsIcon from "@mui/icons-material/Directions";
import { useTheme } from "@mui/material/styles";

import {
  phoneNumber,
  Pages,
  email,
  lsuLink,
  address,
  footerData,
  services,
} from "../../constants/data";

export default function Footer() {
  const theme = useTheme();
  const [emailInput, setEmailInput] = useState("");

  const handleSubscribe = () => {
    // logique pour la souscription
    console.log("Subscribed with email:", emailInput);
  };

  return (
    <AppBar
      position="relative"
      color="transparent"
      sx={{ backgroundColor: theme.palette.text.disabled }}
    >
      <Toolbar>
        <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            sx={{
              maxWidth: 1500,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Première colonne "HANDLIT" */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                paddingTop: 2,
                paddingBottom: 2,
                mixBlendMode: "luminosity",
              }}
            >
              {/* Section du sign up for our newslettre */}
              <Grid
                sx={{
                  paddingBottom: 4,
                  marginRight: { xs: 0, md: theme.spacing(6) },
                }}
                xs={12}
                item
                container
              >
                <Grid item>
                  <Typography
                    align="center"
                    color={theme.palette.secondary.light}
                    sx={{
                      marginBottom: 1,
                      fontSize: 20,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      whiteSpace: "nowrap",
                    }}
                  >
                    SIGN UP FOR OUR NEWSLETTER
                  </Typography>
                </Grid>
                <Grid container rowSpacing={2} sx={{ marginTop: 1 }}>
                  <Grid item rowSpacing={1}>
                    <TextField
                      label="Enter your email"
                      variant="outlined"
                      value={emailInput}
                      onChange={(e) => setEmailInput(e.target.value)}
                      sx={{
                        marginBottom: 2,
                        borderLeft: "3px solid #6200ea",
                        borderRadius: 2,
                        backgroundColor: "#f0f0f0",
                      }}
                    />
                  </Grid>
                  <Grid item rowSpacing={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubscribe}
                      sx={{ height: "56px", marginLeft: 2, width: "150px" }}
                    >
                      Subscribe now
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Typography
                fontSize={24}
                fontWeight={"bold"}
                color={theme.palette.primary.light}
              >
                HANDLIT
              </Typography>
              <Typography
                sx={{ fontSize: 14 }}
                width={"83%"}
                color={theme.palette.secondary.light}
              >
                {footerData}
              </Typography>
            </Grid>

            {/* Colonne des services */}
            <Grid
              item
              container
              sx={{
                flexDirection: "row",
                // justifyContent: 'space-between',
                paddingTop: 2,
                paddingBottom: 2,
              }}
              xs={12}
              md={6}
            >
              <Grid
                sx={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  flexDirection: "column",
                  marginRight: { xs: 0, md: theme.spacing(6) },
                }}
                xs={12}
                md={2.8}
                item
                container
              >
                <Grid item>
                  <Typography
                    align="left"
                    color={theme.palette.secondary.light}
                    sx={{
                      marginBottom: 1,
                      fontSize: 20,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      whiteSpace: "nowrap",
                    }}
                  >
                    NOS SERVICES
                  </Typography>
                </Grid>
                <Grid item container direction="column" spacing={1}>
                  {services.map((service, index) => (
                    <Grid item key={index}>
                      <Link underline="none" href={service.link}>
                        <Typography
                          style={{
                            color: theme.palette.secondary.light,
                            whiteSpace: "nowrap",
                            fontSize: "16px",
                          }}
                          align="left"
                        >
                          {service.name}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {/* Colonne "Links" */}
              <Grid
                sx={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  flexDirection: "column",
                  marginRight: { xs: 0, md: theme.spacing(6) },
                }}
                xs={12}
                md={2.4}
                item
                container
              >
                <Grid item>
                  <Typography
                    align="left"
                    color={theme.palette.secondary.light}
                    sx={{
                      marginBottom: 1,
                      fontSize: 20,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      whiteSpace: "nowrap",
                    }}
                  >
                    LINKS
                  </Typography>
                </Grid>
                <Grid item container direction="column" spacing={1}>
                  {Pages.map((links, index) => (
                    <Grid item key={index}>
                      <Link underline="none" href={links.link}>
                        <Typography
                          style={{
                            color: theme.palette.secondary.light,
                            whiteSpace: "nowrap",
                            fontSize: "16px",
                          }}
                          align="left"
                        >
                          {links.name}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {/* Colonne "Contact Info" */}
              <Grid
                sx={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  flexDirection: "column",
                  marginRight: { xs: 0, md: theme.spacing(6) },
                }}
                xs={12}
                md={2.4}
                item
                container
              >
                <Grid item>
                  <Typography
                    align="left"
                    color={theme.palette.secondary.light}
                    sx={{
                      marginBottom: 1,
                      fontSize: 20,
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      whiteSpace: "nowrap",
                    }}
                  >
                    CONTACT INFO
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  spacing={1}
                  alignItems="left"
                >
                  <Grid item>
                    <Typography
                      color={theme.palette.secondary.light}
                      sx={{ fontSize: 16, fontWeight: "bold" }}
                    >
                      Phone
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      icon={
                        <Grid
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: theme.palette.primary.light,
                          }}
                        >
                          <PhoneOutlined
                            sx={{
                              color: theme.palette.primary.light,
                              height: 15,
                            }}
                          />
                        </Grid>
                      }
                      label={phoneNumber}
                      component="a"
                      href={`tel:${phoneNumber}`}
                      clickable
                      sx={{
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.light,
                        ":hover": {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.light,
                        },
                        marginTop: 1,
                      }}
                    />
                  </Grid>

                  <Grid item sx={{ marginTop: 2 }}>
                    <Typography
                      color={theme.palette.secondary.light}
                      sx={{ fontSize: 16, fontWeight: "bold" }}
                    >
                      Email
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      icon={
                        <Grid
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            color: theme.palette.primary.light,
                          }}
                        >
                          <Email
                            sx={{
                              color: theme.palette.primary.light,
                              height: 15,
                            }}
                          />
                        </Grid>
                      }
                      label={email}
                      component="a"
                      href={`mailto:${email}`}
                      clickable
                      sx={{
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.light,
                        ":hover": {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.light,
                        },
                        marginTop: 1,
                      }}
                    />
                  </Grid>

                  <Grid item sx={{ marginTop: 2 }}>
                    <Typography
                      color={theme.palette.primary.light}
                      sx={{ fontSize: 16, fontWeight: "bold" }}
                    >
                      Address
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      icon={
                        <Grid
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            color: theme.palette.primary.light,
                          }}
                        >
                          <DirectionsIcon
                            sx={{
                              color: theme.palette.primary.light,
                              height: 15,
                            }}
                          />
                        </Grid>
                      }
                      label={address}
                      component="a"
                      href={`http://maps.google.com/?q=${address}`}
                      target="_blank"
                      clickable
                      sx={{
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.dark,
                        ":hover": {
                          backgroundColor: theme.palette.primary.dark,
                          color: theme.palette.primary.light,
                        },
                        marginTop: 1,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid
            width={"100%"}
            height={30}
            justifyContent={"center"}
            marginTop={2}
            padding={0.5}
            marginBottom={2}
            alignContent={"center"}
            textAlign={"center"}
            bgcolor="transparent"
            color={theme.palette.primary.light}
          >
            <Typography sx={{ fontSize: 12 }}>
              <strong>
                &copy;{new Date().getFullYear()} | Designed by LSU TECH
              </strong>
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
