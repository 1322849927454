/* eslint-disable object-curly-newline */
import { Alert, Grid, Typography, Modal, InputAdornment } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from '@mui/material/styles';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import Phone from '@mui/icons-material/Phone';
import * as Yup from 'yup';
import { Formik } from 'formik';

import GoogleMap from './GoogleMap.jsx';
import PrimaryButton from '../../components/forms/buttons/PrimaryButton';
import { address, email, phoneNumber } from '../../constants/data';
import { sendEmail } from '../../apis/contact.api';
import TextInput from '../../components/forms/textField/TextInput';
import Loading from '../../components/loading/Loading';

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('fullName is required'),
  email: Yup.string().email().required('email is required'),
  phone: Yup.string(),
  message: Yup.string().required('Message is required')
});

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 25,
  p: 4,
  borderRadius: 5,
  backgroundColor: '#3AB37C'
};

const Contact = () => {
  const theme = useTheme();
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [errorServer, setErrorServer] = useState(false);

  const navigate = useNavigate();

  const handleCloseModal = () => {
    setEmailSent(false);
  };
  const submitForm = async (values: any) => {
    if (values?.fullName && values?.phone && values?.email && values?.message) {
      await sendEmail(
        values?.fullName,
        values?.email,
        values?.phone,
        values?.message
      )
        .then(async (res: any) => {
          if (res) {
            setLoading(false);
            if (res?.data?.data?.data?.sendEmail) {
              setErrorServer(false);
              setInputError(false);
              setEmailSent(true);
              setTimeout(() => {
                navigate('/');
              }, 3000);
            } else {
              setInputError(false);
              setEmailSent(false);
              setErrorServer(true);
            }
          } else {
            setLoading(true);
          }
        })
        .catch(
          (err: any) => err
          // console.log('Error: ', err);
        );
    } else {
      setInputError(true);
      setErrorServer(false);
    }
  };

  useEffect(() => {
    if (emailSent) {
      setTimeout(() => {
        setEmailSent(false);
      }, 5000);
    }
  }, [emailSent]);

  return (
    <Grid container sx={{ width: '100%', justifyContent: 'center' }}>
      <Grid item sx={{ marginBottom: 1, marginTop: 1 }}>
        {loading && <Loading isLoading={loading} loadingMsg={'Loading...'} />}
      </Grid>
      <Grid
        sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
        container
        p={2}
        margin={2}
        justifyContent="center"
      >
        <Grid sx={{ flexDirection: 'column', textAlign: 'center' }}>
          <Typography
            fontSize={28}
            fontWeight={500}
            color={theme.palette.primary.main}
          >
            CONTACT
          </Typography>
          <CircleIcon
            sx={{ color: theme.palette.primary.main, marginTop: 3 }}
          />
        </Grid>
      </Grid>
      {/*  START */}
      <Grid
        container
        xs={12}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        justifyContent="center"
        sx={{ width: '100%' }}
      >
        <Grid item xs={6} sm={4} md={4} sx={{ margin: 2 }}>
          <Grid
            sx={{
              textAlign: 'left',
              width: '100%',
              marginTop: '15%'
            }}
          >
            <Grid item container direction="row" xs={12}>
              {inputError && (
                <Alert severity="error" sx={{ width: '100%' }}>
                  Please enter all required information.
                </Alert>
              )}
            </Grid>{' '}
            <Grid item container direction="row" xs={12}>
              {errorServer && (
                <Alert severity="error" sx={{ width: '100%' }}>
                  Sorry, an error occurred while processing your request. Please
                  try again.
                </Alert>
              )}
            </Grid>
            <Formik
              initialValues={{
                email: '',
                fullName: '',
                phone: '',
                message: ''
              }}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                // console.log("salut loic");
                setLoading(true);
                // console.log(values);
                await submitForm(values);
              }}
            >
              {({ handleChange, handleSubmit, values, errors, touched }) => (
                <Grid>
                  <Grid item xs={12} sx={{ margin: 2 }}>
                    <TextInput
                      variant="standard"
                      required
                      // label="FULLNAME"
                      placeholder="FULLNAME"
                      value={values.fullName}
                      fullWidth
                      name="fullName"
                      type="text"
                      error={
                        errors.fullName && touched.fullName
                          ? errors.fullName
                          : ''
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ marginRight: 1 }}
                          >
                            <PermIdentityIcon />
                          </InputAdornment>
                        )
                      }}
                      onChange={handleChange('fullName')}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ margin: 2 }}>
                    <TextInput
                      required
                      variant="standard"
                      // label="EMAIL"
                      placeholder="EMAIL"
                      value={values.email}
                      fullWidth
                      name="email"
                      type="text"
                      error={errors.email && touched.email ? errors.email : ''}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ marginRight: 1 }}
                          >
                            <EmailIcon />
                          </InputAdornment>
                        )
                      }}
                      onChange={handleChange('email')}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ margin: 2 }}>
                    <TextInput
                      // label="PHONE"
                      variant="standard"
                      placeholder="PHONE"
                      value={values.phone}
                      type="tel"
                      required={false}
                      name="phone"
                      error={errors.phone && touched.phone ? errors.phone : ''}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ marginRight: 1 }}
                          >
                            <Phone />
                          </InputAdornment>
                        )
                      }}
                      onChange={handleChange('phone')}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ margin: 2 }}>
                    <TextInput
                      rows={5}
                      variant="standard"
                      multiline={true}
                      // label={'MESSAGE'}
                      type={'text'}
                      placeholder="MESSAGE"
                      fullWidth={true}
                      onChange={handleChange('message')}
                      value={values.message}
                      name={'message'}
                      required={true}
                      error={
                        errors.message && touched.message ? errors.message : ''
                      }
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    sx={{
                      justifyContent: 'left',
                      margin: 2,
                      marginBottom: 5,
                      marginTop: 2
                    }}
                  >
                    <PrimaryButton
                      title="Send message"
                      variant="outlined"
                      fontSize={16}
                      fontWeight={200}
                      onPress={handleSubmit}
                      // disabled={
                      //   !values.email || !values.fullName || !values.message
                      // }
                      style={{
                        width: '100%',
                        height: 40,
                        borderRadius: 1,
                        borderColor: theme.palette.primary.main,
                        borderWidth: 0.1,
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.light,
                        ':hover': {
                          color: theme.palette.primary.light,
                          backgroundColor: theme.palette.primary.main
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} md={4} sx={{ margin: 2 }}>
          <Grid
            sx={{
              textAlign: 'left',
              width: '100%',
              marginTop: '15%'
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={400}
              color={theme.palette.secondary.main}
            >
              STAY IN TOUCH WITH ME
            </Typography>
            <Typography
              gutterBottom
              textAlign={'left'}
              variant="h4"
              fontWeight={500}
              color={theme.palette.primary.main}
            >
              Quick Contact
            </Typography>
            <br />
            <Typography
              fontSize={18}
              fontWeight={'bold'}
              color={theme.palette.secondary.main}
            >
              Just to say hi !!
            </Typography>
            <br />
            <Typography
              fontSize={16}
              fontWeight={400}
              color={theme.palette.secondary.main}
            >
              If you have any questions simply use the following contact
              details.
            </Typography>
            <br />
            <Typography
              fontSize={16}
              fontWeight={400}
              color={theme.palette.secondary.main}
            >
              Business Owners to take their Online Presence to the next level.
              We are in the business of Bulk SMS, Digital Marketing
            </Typography>
            <br />
          </Grid>
          <Grid
            sx={{
              textAlign: 'left',
              width: '100%',
              marginBottom: '20%'
            }}
          >
            <Typography
              fontSize={16}
              fontWeight={400}
              color={theme.palette.secondary.main}
            >
              <strong>ADDRESS: </strong>
              {address}
            </Typography>
            <Typography
              fontSize={16}
              fontWeight={400}
              color={theme.palette.secondary.main}
            >
              <strong>EMAIL: </strong>
              {email}
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              color={theme.palette.secondary.main}
            >
              <strong>PHONE NUMBER: </strong>
              {phoneNumber}
            </Typography>
          </Grid>
        </Grid>
        {emailSent && (
          <Modal
            open={emailSent}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Grid
              container
              sx={modalStyle}
              textAlign="center"
              justifyContent={'center'}
              direction="column"
            >
              <Alert
                severity="success"
                sx={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  fontSize: 14,
                  fontWeight: 'bold',
                  letterSpacing: 0.5
                }}
              >
                Your message has been sent successfully
              </Alert>
            </Grid>
          </Modal>
        )}
      </Grid>
      {/* END */}
      <Grid
        sx={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 3 } }}
        container
        p={2}
        justifyContent="center"
      >
        <Grid
          xs={12}
          item
          container
          sx={{ justifyContent: 'center', marginBottom: 7, marginTop: 2 }}
        >
          <GoogleMap />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contact;
