export default {
  white: "#fff",
  black: "#000",
  first: "#3e56d0",
  primary: "#7c68ee",
  secondary: "#fe9801",
  third: "#042d4d",
  mediumGray: "#797a98",
  lightGray: "#f7f9f8",
  darkGray: "#404040",
  danger: "#ff5252",
  main: "#ffff",
  border: "#3160b3",
  light: "#313131",
  success: "#4caf50",
};
