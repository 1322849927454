import React from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// import Contact from '../../layouts/contact/Contact';
// import PictureMobileStepper from '../../components/pictureMobileStepper/PictureMobileStepper';
// import { imageContact } from '../../constants/data';
import NewContact from '../../layouts/contact/NewContact';

export default function ContactUsScreen() {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        width: '100%',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.light
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: 1500,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '12%'
        }}
      >
        {/* <PictureMobileStepper pictures={imageContact} /> */}
        <Grid item container sx={{ justifyContent: 'center' }} xs={12}>
          <NewContact />
          {/* <Contact /> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
