/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  InputAdornment,
  IconButton,
  TextField,
  Alert,
  Paper,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PasswordIcon from "@mui/icons-material/Lock";
import AccountCircle from "@mui/icons-material/AccountCircle";

import PrimaryButton from "../../components/forms/buttons/PrimaryButton";

const lightImages = require("../../assets/ResetPassword-rafiki.png");
const darkImages = require("../../assets/Reset password-rafiki.png");

export default function ResetPassword() {
  const theme = useTheme();

  const [username, setUsername] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [inputError, setInputError] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] =
    React.useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmNewPassword = () =>
    setShowConfirmNewPassword((show) => !show);

  const handleUsername = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const tmp_username = event.target.value;
    setUsername(tmp_username);
  };

  const handleOldPassword = (event: any) => {
    const tmp_old_password = event.target.value;
    setOldPassword(tmp_old_password);
  };

  const handleNewPassword = (event: any) => {
    const tmp_new_password = event.target.value;
    setNewPassword(tmp_new_password);
  };

  const handleNewPasswordConfirm = (event: any) => {
    const tmp_new_passwordConfirm = event.target.value;
    setConfirmNewPassword(tmp_new_passwordConfirm);
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    if (newPassword === confirmNewPassword) {
      setInputError(false);
      try {
      } catch (error: any) {
        // eslint-disable-next-line no-console
        // console.log(
        //   '❌ Unable to change password. Please try again.',
        //   error.message
        // );
        setAuthError(true);
      }
    } else {
      setInputError(true);
    }
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{
        backgroundColor: theme.palette.primary.light,
        flexDirection: { xs: "column-reverse", md: "row" },
        flexWrap: { xs: "wrap", md: "nowrap" },
        paddingTop: "118px",
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{ margin: 2, order: { xs: 2, md: 2 } }}
        className="password-form"
      >
        <Grid container justifyContent="center">
          {theme.palette.mode === "dark" ? (
            <img src={darkImages} alt="image" style={{ width: "80%" }} />
          ) : (
            <img src={lightImages} alt="image" style={{ width: "80%" }} />
          )}
        </Grid>
      </Grid>
      <Paper
        sx={{
          width: { xs: "100%", md: "42%" },
          backgroundColor: theme.palette.primary.light,
          margin: {
            xs: 0, // Aucune marge pour les écrans de petite taille
            md: 2, // Marge de 2 pour les écrans de taille moyenne et plus grands
          },
        }}
      >
        <Grid item xs={12} sx={{ marginTop: 5 }}>
          <Grid
            item
            xs={12}
            sx={{ margin: { xs: 2, md: 4 }, textAlign: "center" }}
          >
            <Typography
              fontSize={28}
              fontWeight={500}
              color={theme.palette.primary.main}
            >
              Reset Password
            </Typography>
          </Grid>
          {inputError && (
            <Alert severity="error" sx={{ margin: 8, padding: 2 }}>
              The information provided is not correct, please try again!!.
            </Alert>
          )}
          {authSuccess && (
            <Alert severity="success" sx={{ margin: 8, padding: 2 }}>
              Password changed successfully.
            </Alert>
          )}
          {authError && (
            <Alert severity="error" sx={{ margin: 8, padding: 2 }}>
              Oops an error occured please try again!
            </Alert>
          )}
          <Grid
            item
            container
            direction="row"
            xs={12}
            justifyContent={"center"}
          >
            <Grid item xs={12} md={6} sx={{ margin: 4, padding: 2 }}>
              <TextField
                label="Username"
                placeholder="Username"
                value={username}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleUsername(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ margin: 4, padding: 2 }}>
              <TextField
                label="Enter Your Received Password"
                placeholder="Password"
                fullWidth
                value={oldPassword}
                required
                id="standard-password-input"
                type={showOldPassword ? "text" : "password"}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowOldPassword}
                        edge="end"
                      >
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleOldPassword(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ margin: 4, padding: 2 }}>
              <TextField
                label="Enter Your New Password"
                placeholder="Enter Your New Password"
                required
                value={newPassword}
                fullWidth
                id="standard-password-input"
                type={showNewPassword ? "text" : "password"}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="   password visibility"
                        onClick={handleClickShowNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleNewPassword(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ margin: 4, padding: 2 }}>
              <TextField
                label="Re-Enter Your New Password"
                placeholder="Confirm Password"
                required
                value={confirmNewPassword}
                fullWidth
                id="standard-password-input"
                type={showConfirmNewPassword ? "text" : "password"}
                autoComplete="current-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="   password visibility"
                        onClick={handleClickShowConfirmNewPassword}
                        edge="end"
                      >
                        {showConfirmNewPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleNewPasswordConfirm(e)}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ margin: { xs: 2, md: 4 }, textAlign: "center" }}
          >
            <PrimaryButton
              title="SUBMIT"
              variant="outlined"
              fontSize={16}
              fontWeight={500}
              onPress={submitForm}
              style={{
                width: "40%",
                height: 40,
                borderRadius: 1,
                color: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.main,
                ":hover": {
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.light,
                },
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
