import { Grid, Typography } from "@mui/material";
import React from "react";
import { useResponsiveQueries } from "../../../config/useResponsiveQueries";
import { WEB_MAX_WIDTH } from "../../../constants/mediaQueryConstants";
import Title from "../../../components/title/Title";
import { servicesCard } from "../../../constants/data";
import CustomCard from "../../../components/card/CustomCard";

export default function HomeServiceLayout() {
  const { mobileMaxWidth, customMaxWidth } = useResponsiveQueries();
  const firstFourServices = servicesCard.slice(0, customMaxWidth ? 3 : 4);
  return (
    <Grid
      container
      sx={{
        width: "100%",
        justifyContent: "center",
      }}
    >
      <Grid container sx={{ maxWidth: WEB_MAX_WIDTH, flexDirection: "column" }}>
        <Grid
          sx={{
            mb: 2,
            mt: 0,
            textAlign: "center",
            flexDirection: "column",
            padding: mobileMaxWidth
              ? "0px var(--Spacing-spacing-3xl, 5px)"
              : "0px var(--Spacing-spacing-3xl, 40px)",
          }}
        >
          <Title
            title={"What We Provide".toUpperCase()}
            fontSize={mobileMaxWidth ? 18 : 20}
            fontWeight={600}
            style={{ m: 2, my: 0 }}
          />
          <Typography
            lineHeight={"normal"}
            fontFamily={"Poppins"}
            fontSize={mobileMaxWidth ? 28 : 32}
            fontWeight={700}
          >
            Our Services
          </Typography>
        </Grid>
        <Grid
          container
          spacing={4} // Ajout de l'espace entre les cartes
          justifyContent={customMaxWidth ? "center" : "flex-start"}
          sx={{
            mt: 2,
            padding: mobileMaxWidth ? "0px 10px" : "0px 40px",
          }}
        >
          {firstFourServices.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <CustomCard
                key={index}
                maxWidth={mobileMaxWidth ? "100%" : "345px"}
                imageSrc={service.image}
                title={service.title}
                description={service.description}
                variant="info"
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
