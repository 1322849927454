import React from "react";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { WEB_MAX_WIDTH } from "../../constants/mediaQueryConstants";
import HomeServiceLayout from "../../layouts/home/services/HomeServiceLayout";
import Banner1 from "../../layouts/home/banner/Banner1";
import HomeTeamLayout from "../../layouts/home/teams/HomeTeamLayout";
import HomeBlogLayout from "../../layouts/home/blogs/HomeBlogLayout";
import Banner2 from "../../layouts/home/banner/Banner2";
import HomeOurCompanyContent from "../../layouts/home/aboutUs/HomeOurCompanyContent";
import HomeOurCompanyImage from "../../layouts/home/aboutUs/HomeOurCompanyImage";
import HomeChooseUsContent from "../../layouts/home/ChooseUs/HomeChooseUsContent";
import HomeChooseUsImage from "../../layouts/home/ChooseUs/HomeChooseUsImage";
import HomeHeaderContent from "../../layouts/home/header/HomeHeaderContent";
import HomeHeaderImage from "../../layouts/home/header/HomeHeaderImage";
import HomeTrushUsContent from "../../layouts/home/TrushUs/HomeTrushUsContent";
import HomeTrushUsImage from "../../layouts/home/TrushUs/HomeTrushUsImage";

export default function Home() {
  const theme = useTheme();
  return (
    <Grid
      sx={{
        overflowX: "hidden",
        background: theme.palette.primary.light,
        // px: 1,
      }}
    >
      <Grid container item sx={{ justifyContent: "center" }}>
        <Grid
          container
          item
          spacing={1}
          sx={{
            width: WEB_MAX_WIDTH,
            pt: 15,
            background:
              "linear-gradient(180deg, #7c68ee 20%, rgba(124, 104, 238, 0.7) 35%, rgba(124, 104, 238, 0.5) 80%)",
            // "linear-gradient(180deg, #301274 15%, #0057a9 35%, #089cd0 80%)",
          }}
        >
          <Grid item xs={12} md={6}>
            <HomeHeaderContent />
          </Grid>
          <Grid item xs={12} md={6} sx={{ pr: 3 }}>
            <HomeHeaderImage />
          </Grid>
          <Grid item xs={12} md={12} className="home-header-image"></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 5 }}>
        <HomeServiceLayout />
      </Grid>
      <Grid container item sx={{ justifyContent: "center" }}>
        <Grid
          container
          item
          spacing={3}
          sx={{
            mt: 5,
            p: 5,
            width: WEB_MAX_WIDTH,
            background: theme.palette.secondary.dark,
          }}
        >
          <Grid item xs={12} md={6}>
            <HomeOurCompanyContent />
          </Grid>
          <Grid item xs={12} md={6} sx={{ pr: 3 }}>
            <HomeOurCompanyImage />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sx={{ justifyContent: "center" }}>
        <Grid
          container
          item
          spacing={3}
          sx={{ mt: 5, px: 5, width: WEB_MAX_WIDTH }}
        >
          <Grid item xs={12} md={6}>
            <HomeChooseUsImage />
          </Grid>
          <Grid item xs={12} md={6} sx={{ pr: 3 }}>
            <HomeChooseUsContent />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sx={{ justifyContent: "center" }}>
        <Grid
          container
          item
          spacing={3}
          sx={{ mt: 5, px: 5, width: WEB_MAX_WIDTH }}
        >
          <Grid item xs={12} md={6}>
            <HomeTrushUsContent />
          </Grid>
          <Grid item xs={12} md={6} sx={{ pr: 3 }}>
            <HomeTrushUsImage />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 5 }}>
        <Banner1 />
      </Grid>
      <Grid item xs={12} sx={{ mt: 5 }}>
        <HomeTeamLayout />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 5, p: 5, background: theme.palette.secondary.dark }}
      >
        <HomeBlogLayout />
      </Grid>
      <Grid item xs={12} sx={{ mb: 5 }}>
        <Banner2 />
      </Grid>
    </Grid>
  );
}
