import { Grid, Typography, Button, Box, LinearProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

import CustomCard from "../../components/card/CustomCard";
import Banner2 from "../../layouts/home/banner/Banner2";
import Banner1 from "../../layouts/home/banner/Banner1";
import Title from "../../components/title/Title";
import { useResponsiveQueries } from "../../config/useResponsiveQueries";
// import HomeBlogLayout from "../home/blogs/HomeBlogLayout";

const lightImages = require("../../assets/Computer login-rafiki.png");

const NewAbout = () => {
  const { mobileMaxWidth } = useResponsiveQueries();

  const progressData = [
    { label: "IT Consulting", value: 92 },
    { label: "Managed IT Service", value: 82 },
    { label: "Cloud Solutions", value: 69 },
    { label: "AI Powered Services", value: 53 },
  ];

  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        width: "100%",
        justifyContent: "center",
        paddingTop: "var(--Spacing-spacing-3xl, 50px)",
      }}
    >
      <Grid
        sx={{
          // width: "100%",
          px: { xs: 2, md: 8 }, // Padding horizontal
          py: { xs: 2, md: 3 }, // Padding vertical
          backgroundColor: "#f0f0f0", // Couleur de fond
        }}
        container
        marginTop={4}
        justifyContent="center"
        spacing={2}
      >
        <Grid
          sx={{
            mb: 2,
            mt: 0,
            textAlign: "center",
            flexDirection: "column",
            padding: mobileMaxWidth
              ? "0px var(--Spacing-spacing-3xl, 5px)"
              : "0px var(--Spacing-spacing-3xl, 40px)",
          }}
        >
          <Title
            title={"Who are we?".toUpperCase()}
            fontSize={mobileMaxWidth ? 18 : 20}
            fontWeight={600}
            style={{ m: 2, my: 0 }}
          />
          <Typography
            lineHeight={"normal"}
            fontFamily={"Poppins"}
            fontSize={mobileMaxWidth ? 28 : 32}
            fontWeight={700}
          >
            About Us
          </Typography>
        </Grid>

        <Grid container direction={"row"} width={"100%"}>
          <Grid sx={{ margin: 1 }}>
            <CustomCard
              imageSrc={lightImages}
              title="Flexibility & Responsive"
              description="Our team is highly adaptable and responsive to the changing needs of our clients, ensuring that we deliver the best solutions in a timely manner."
              variant="info"
            />
          </Grid>

          <Grid sx={{ margin: 1 }}>
            <CustomCard
              imageSrc={lightImages}
              title="Dedicated Teams"
              description="We have dedicated teams of experts in software development, cybersecurity, and AI who are committed to delivering high-quality services."
              variant="info"
            />
          </Grid>
          <Grid sx={{ margin: 1 }}>
            <CustomCard
              imageSrc={lightImages}
              title="Focusing on Business"
              description="Our team possesses extensive experience in cybersecurity and artificial intelligence, providing cutting-edge solutions to protect and enhance your business."
              variant="info"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mb: 5 }}>
        <Banner1 />
      </Grid>

      <Grid container spacing={4} alignItems="center" margin={4} sx={{ px: 2 }}>
        {/* Section gauche avec texte et barres de progression */}
        <Grid item xs={12} md={6}>
          <Typography variant="overline" color={theme.palette.primary.main}>
            ABOUT OUR COMPANY
          </Typography>
          <Typography variant="h3" gutterBottom>
            Providing Your Business With A Quality IT Service is Our Passion
          </Typography>
          <Typography variant="body1" paragraph>
            We are a software development company with a strong focus on
            cybersecurity and artificial intelligence. Our team is dedicated to
            delivering innovative and secure solutions to help your business
            thrive in the digital age.
          </Typography>

          {/* Boucle pour les barres de progression */}
          {progressData.map((item, index) => (
            <Box key={index} mb={2}>
              <Typography variant="body2">{item.label}</Typography>
              <LinearProgress variant="determinate" value={item.value} />
            </Box>
          ))}

          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mt: 3, px: 4 }}
          >
            Learn More
          </Button>
        </Grid>

        {/* Section droite avec image */}
        <Grid item xs={12} md={6}>
          <Box sx={{ position: "relative" }}>
            <img
              src={lightImages}
              alt="IT Service"
              style={{ width: "100%", borderRadius: "8px" }}
            />
            {/* <Box
              sx={{
                position: "absolute",
                bottom: "20px",
                left: "20px",
                background: "#fff",
                padding: "10px 20px",
                borderRadius: "8px",
                textAlign: "center",
              }}
            >
              <Typography variant="h3" color="primary">
                23
              </Typography>
              <Typography variant="body2">Years of Experience</Typography>
            </Box> */}
          </Box>
        </Grid>
      </Grid>

      {/* <Grid
        item
        xs={12}
        sx={{ mt: 5, p: 5, background: theme.palette.secondary.dark }}
      >
        <HomeBlogLayout />
      </Grid> */}

      <Grid item xs={12} sx={{ mb: 5 }}>
        <Banner2 />
      </Grid>
    </Grid>
  );
};

export default NewAbout;
