import { createContext } from 'react';
import { LoadContextType } from '../interfaces/loadContextType.interface';

const AppContext = createContext<
  | any
  | null
  | LoadContextType
  | LoadContextType['payMethod']
  | LoadContextType['isLoading']
  | LoadContextType['setisLoading']
  | LoadContextType['setPayMethod']
>(null);

export default AppContext;
