import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link,
  Box,
  IconButton,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import { CardComponentInterface } from "../../interfaces/cardComponent/cardComponent.interface";
import colors from "../../config/colors";

export default function CustomCard({
  imageSrc,
  title,
  subTitle,
  description,
  contact,
  email,
  icons,
  variant,
  height,
  width,
  maxWidth,
}: CardComponentInterface) {
  return (
    <Card
      sx={{
        maxWidth: maxWidth || 345,
        width: width || "100%",
        height: height || "auto",
        margin: "0 auto",
        boxShadow: "0px 10px 30px rgba(0,0,0,0.1)",
        borderRadius: 4,
        textAlign: "center",
        backgroundColor: colors.white,
        position: "relative",
      }}
    >
      {variant === "team" && (
        <>
          <CardMedia
            component="img"
            height={height || 300}
            image={imageSrc}
            alt={title}
            sx={{
              filter: "brightness(50%)",
              position: "relative",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              gap: 2,
            }}
          >
            <IconButton sx={{ backgroundColor: "#fff" }}>
              <FacebookIcon />
            </IconButton>
            <IconButton sx={{ backgroundColor: "#fff" }}>
              <TwitterIcon />
            </IconButton>
            <IconButton sx={{ backgroundColor: "#fff" }}>
              <LinkedInIcon />
            </IconButton>
          </Box>

          {/* Ajout du titre et du sous-titre sous l'image */}
          <CardContent>
            <Typography
              variant="h6"
              color="textSecondary"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              {title}
            </Typography>
            <Typography variant="body2">{subTitle}</Typography>
          </CardContent>
        </>
      )}

      {variant === "info" && (
        <>
          <CardMedia
            component="img"
            height={height || "100"}
            image={imageSrc}
            alt={title}
            sx={{
              margin: "auto",
              width: 150,
              height: 150,
              padding: 1,
            }}
          />
          <CardContent>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold", marginBottom: 1 }}
            >
              {title}
            </Typography>
            {description && (
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {description}
              </Typography>
            )}
          </CardContent>
        </>
      )}

      {variant === "office" && (
        <CardContent>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            {title}
          </Typography>
          {description && (
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {description}
            </Typography>
          )}
          {contact && (
            <Typography variant="body2" color="textPrimary" gutterBottom>
              <Link href={`tel:${contact}`} underline="hover" color="inherit">
                {contact}
              </Link>
            </Typography>
          )}
          {email && (
            <Typography variant="body2" color="textPrimary">
              <Link href={`mailto:${email}`} underline="hover" color="inherit">
                {email}
              </Link>
            </Typography>
          )}
        </CardContent>
      )}
    </Card>
  );
}
