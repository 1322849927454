import React from 'react';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Contact from '../../layouts/contact/Contact';
import News from '../../layouts/news/News';

export default function NewScreen() {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        width: '100%',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.light
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: 1500,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {/* <PictureMobileStepper pictures={imageNews} /> */}
        <Grid item container sx={{ justifyContent: 'center' }} xs={12}>
          <News />
        </Grid>
        <Grid item container sx={{ justifyContent: 'center' }} xs={12}>
          <Contact />
        </Grid>
      </Grid>
    </Grid>
  );
}
