/* eslint-disable object-curly-newline */
import React, { useState } from 'react';
import {
  Grid,
  Alert,
  Box,
  InputAdornment,
  Paper,
  useTheme
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import DialpadIcon from '@mui/icons-material/Dialpad';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Phone from '@mui/icons-material/Phone';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  getLocalStorageEmployerAddress,
  setInLocalStorageEmployerAddress
} from '../config/localStorage';
import { Step4Interface } from '../interfaces/stepped/step4.interface';
import { EmployerAddressInterface } from '../interfaces/companyCreation/employerAddress.interface';
import CountrySelect from '../components/forms/textField/CountrySelect';
import PrimaryButton from '../components/forms/buttons/PrimaryButton';
import TextInput from '../components/forms/textField/TextInput';
import CitySelected from '../components/forms/textField/CitySelected';

const validationSchema = Yup.object().shape({
  addressLine1: Yup.string().required('addressLine1 is required'),
  phone: Yup.number().required('phoneNumber is required'),
  addressLine2: Yup.string().required('addressLine2 is required'),
  state: Yup.string().required('state is required'),
  zipCode: Yup.string()
    .required('zipCode is required')
    .matches(/^[0-9]{5,5}$/, 'Is not in correct format, zipCode has 5 digits')
});

export default function Step4({
  handleBack,
  handleNext,
  activeStep,
  steps
}: Step4Interface) {
  const theme = useTheme();

  const employeAddress = getLocalStorageEmployerAddress();
  const [countryObject, setCountryObject] = useState({
    code: '',
    label: employeAddress?.country ? employeAddress?.country : '',
    phone: ''
  });

  const [city, setCity] = useState(
    employeAddress?.city ? employeAddress?.city : ''
  );

  const [inputError, setInputError] = useState(false);
  const [country, setCountry] = useState(
    employeAddress?.country ? employeAddress?.country : ''
  );

  const save = (values: any) => {
    const data: EmployerAddressInterface = {
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city,
      phoneNumber: values.phone,
      state: values.state,
      zipCode: values.zipCode,
      country
    };
    // console.log(data);
    setInputError(false);
    setInLocalStorageEmployerAddress(data);
    handleNext();
  };
  return (
    <Paper sx={{ padding: 5, margin: 1, marginBottom: '10%' }}>
      <Grid item sx={{ margin: 2 }}>
        {inputError && (
          <Alert severity="error" sx={{ margin: 2 }}>
            Please enter all required information correctly.
          </Alert>
        )}
      </Grid>
      <Formik
        initialValues={{
          addressLine1: employeAddress?.addressLine1
            ? employeAddress?.addressLine1
            : '',
          addressLine2: employeAddress?.addressLine2
            ? employeAddress?.addressLine2
            : '',
          state: employeAddress?.state ? employeAddress?.state : '',
          zipCode: employeAddress?.zipCode ? employeAddress?.zipCode : '',
          phone: employeAddress?.phoneNumber ? employeAddress?.phoneNumber : ''
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          save(values);
        }}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <CountrySelect
                width={'100%'}
                countrySelected={countryObject}
                setCountryLabel={setCountry}
                setCountrySelected={setCountryObject}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="State"
                placeholder="State"
                required
                value={values.state}
                name="state"
                fullWidth
                type={'text'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <LanguageIcon />
                    </InputAdornment>
                  )
                }}
                error={errors.state && touched.state ? errors.state : ''}
                onChange={handleChange('state')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <CitySelected
                width={'100%'}
                countryName={country}
                citySelected={city}
                setCitySelected={setCity}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="AddressLine1"
                placeholder="AddressLine1"
                fullWidth
                required
                name="addressLine1"
                value={values.addressLine1}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <LocationOnIcon />
                    </InputAdornment>
                  )
                }}
                error={
                  errors.addressLine1 && touched.addressLine1
                    ? errors.addressLine1
                    : ''
                }
                onChange={handleChange('addressLine1')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="AddressLine2"
                placeholder="AddressLine2"
                fullWidth
                value={values.addressLine2}
                name="addressLine1"
                required
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <LocationOnIcon />
                    </InputAdornment>
                  )
                }}
                error={
                  errors.addressLine1 && touched.addressLine1
                    ? errors.addressLine1
                    : ''
                }
                onChange={handleChange('addressLine2')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Country Code"
                placeholder="Country Code"
                required
                value={`+${countryObject?.phone}`}
                disabled={true}
                fullWidth
                type={'text'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <DialpadIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Phone Number"
                placeholder="Phone Number"
                value={values.phone}
                type="number"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <Phone />
                    </InputAdornment>
                  )
                }}
                name={'phone'}
                error={errors.phone && touched.phone ? errors.phone : ''}
                onChange={handleChange('phone')}
              />
            </Grid>
            <Grid item xs={12} sx={{ margin: 2, padding: 2 }}>
              <TextInput
                label="Zip Code"
                placeholder="Zip Code"
                required
                name={'zipCode'}
                value={values.zipCode}
                fullWidth
                type={'text'}
                error={errors.zipCode && touched.zipCode ? errors.zipCode : ''}
                onChange={handleChange('zipCode')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginRight: 1 }}>
                      <DialpadIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid
              sx={{
                margin: 2,
                padding: 2,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <PrimaryButton
                title="Back"
                variant="outlined"
                fontSize={16}
                fontWeight={500}
                disabled={activeStep === 0}
                onPress={handleBack}
                style={{
                  width: '20%',
                  height: 40,
                  borderRadius: 1,
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.light,
                  ':hover': {
                    color: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.main
                  }
                }}
              />
              <Box sx={{ flex: '1 1 auto' }} />
              <PrimaryButton
                title={activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                variant="outlined"
                fontSize={16}
                fontWeight={500}
                disabled={
                  !values.phone ||
                  !values.addressLine1 ||
                  !values.addressLine2 ||
                  !values.state ||
                  !values.zipCode
                }
                onPress={handleSubmit}
                style={{
                  width: '20%',
                  height: 40,
                  borderRadius: 1,
                  color: theme.palette.primary.light,
                  backgroundColor: theme.palette.primary.main,
                  ':hover': {
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.light
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </Paper>
  );
}
