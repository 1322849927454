import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Modal,
  Alert,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";

import { sendEmail } from "../../apis/contact.api";
import GoogleMap from "./GoogleMap.jsx";
import CustomCard from "../../components/card/CustomCard";
import Loading from "../../components/loading/Loading";
import { useTheme } from "@mui/material/styles";
import { WEB_MAX_WIDTH } from "../../constants/mediaQueryConstants";
import { useResponsiveQueries } from "../../config/useResponsiveQueries";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("fullName is required"),
  email: Yup.string().email().required("email is required"),
  phone: Yup.string(),
  subject: Yup.string(),
  message: Yup.string().required("Message is required"),
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 25,
  p: 4,
  borderRadius: 5,
  backgroundColor: "#3AB37C",
};

const NewContact = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [subject, setSubject] = useState();

  const theme = useTheme();
  const { tabletMaxWidth, mobileMaxWidth } = useResponsiveQueries();

  const navigate = useNavigate();
  const handleCloseModal = () => {
    setEmailSent(false);
  };

  const submitForm = async (values: any) => {
    if (values?.fullName && values?.phone && values?.email && values?.message) {
      await sendEmail(
        values?.fullName,
        values?.email,
        values?.phone,
        values?.message
      )
        .then(async (res: any) => {
          if (res) {
            setLoading(false);
            if (res?.data?.data?.data?.sendEmail) {
              setErrorServer(false);
              setInputError(false);
              setEmailSent(true);
              setTimeout(() => {
                navigate("/");
              }, 3000);
            } else {
              setInputError(false);
              setEmailSent(false);
              setErrorServer(true);
            }
          } else {
            setLoading(true);
          }
        })
        .catch(
          (err: any) => err
          // console.log('Error: ', err);
        );
    } else {
      setInputError(true);
      setErrorServer(false);
    }
  };

  useEffect(() => {
    if (emailSent) {
      setTimeout(() => {
        setEmailSent(false);
      }, 5000);
    }
  }, [emailSent]);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        width: "100%",
        padding: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Formik
        initialValues={{
          email: "",
          fullName: "",
          phone: "",
          subject: "",
          message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          console.log("Hello", values);
          setLoading(true);
          await submitForm(values);
        }}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "center",
                px: { xs: 2, md: 8 },
                py: { xs: 2, md: 3 },
                backgroundColor: "#f0f0f0",
              }}
              marginTop={4}
            >
              <Grid
                sx={{ px: { xs: 2, md: 8 }, pb: { xs: 2, md: 3 } }}
                container
                p={2}
                margin={2}
                justifyContent="center"
              >
                <Grid sx={{ flexDirection: "column", textAlign: "center" }}>
                  <Typography fontSize={28} fontWeight={500}>
                    Contact Us
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Your Name"
                  variant="outlined"
                  value={values.fullName}
                  sx={{
                    marginBottom: 2,
                    borderLeft: "3px solid #6200ea",
                    borderRadius: 2,
                  }}
                  required={true}
                  onChange={handleChange("fullName")}
                  error={touched.fullName && Boolean(errors.fullName)}
                  // helperText={touched.fullName && errors.fullName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Your Email"
                  variant="outlined"
                  value={values.email}
                  sx={{
                    marginBottom: 2,
                    borderLeft: "3px solid #6200ea",
                    borderRadius: 2,
                  }}
                  required={true}
                  onChange={handleChange("email")}
                  error={touched.email && Boolean(errors.email)}
                  // helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid item xs={12} sm={6} rowSpacing={1}>
                <TextField
                  fullWidth
                  label="Your phone"
                  variant="outlined"
                  value={values.phone}
                  sx={{ borderLeft: "3px solid #6200ea", borderRadius: 2 }}
                  onChange={handleChange("phone")}
                  error={touched.phone && Boolean(errors.phone)}
                  // helperText={touched.phone && errors.phone}
                />
              </Grid>
              <Grid item xs={12} sm={6} rowSpacing={1}>
                <TextField
                  fullWidth
                  label="Your Subject"
                  variant="outlined"
                  value={values.subject}
                  sx={{ borderLeft: "3px solid #6200ea", borderRadius: 2 }}
                  required={false}
                  onChange={handleChange("subject")}
                  error={touched.subject && Boolean(errors.subject)}
                  // helperText={touched.subject && Boolean(errors.subject)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Your Message"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={values.message}
                  sx={{ borderLeft: "3px solid #6200ea", borderRadius: 2 }}
                  onChange={handleChange("message")}
                  required={false}
                  error={touched.message && Boolean(errors.message)}
                  // helperText={touched.message && Boolean(errors.message)}
                />
              </Grid>

              <Grid item xs={12} textAlign="left">
                <Button
                  variant="contained"
                  sx={{ bgcolor: "#6200ea" }}
                  type="submit"
                >
                  Send Message
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>

      {emailSent && (
        <Modal
          open={emailSent}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            sx={modalStyle}
            textAlign="center"
            justifyContent={"center"}
            direction="column"
          >
            <Alert
              severity="success"
              sx={{
                backgroundColor: "transparent",
                color: "#fff",
                fontSize: 14,
                fontWeight: "bold",
                letterSpacing: 0.5,
              }}
            >
              Your message has been sent successfully
            </Alert>
          </Grid>
        </Modal>
      )}

      <Grid
        sx={{
          px: { xs: 2, md: 8 }, // Padding horizontal
          py: { xs: 2, md: 3 }, // Padding vertical
          backgroundColor: "#f0f0f0", // Couleur de fond
        }}
        container
        marginTop={4}
        justifyContent="center"
        spacing={2}
      >
        <Grid
          sx={{ px: { xs: 2, md: 8 }, pb: { xs: 2, md: 3 } }}
          container
          p={2}
          margin={2}
          justifyContent="center"
        >
          <Grid sx={{ flexDirection: "column", textAlign: "center" }}>
            <Typography
              fontSize={28}
              fontWeight={500}
              // color={theme.palette.primary.main}
            >
              Contact Info
            </Typography>
          </Grid>
        </Grid>

        <Grid sx={{ margin: 1 }}>
          <CustomCard
            title="USA"
            description="Houston, Texas, 77079"
            contact="+1 (650) - 203 - 6699"
            email="customerservice@letservu.com"
            variant="office"
          />
        </Grid>

        <Grid sx={{ margin: 1 }}>
          <CustomCard
            title="Canada"
            description="Ottawa, Ontario, Canada"
            contact="+1 (581) - 922 - 4231"
            email="customerservice@letservu.com"
            variant="office"
          />
        </Grid>

        <Grid sx={{ margin: 1 }}>
          <CustomCard
            title="France"
            description="Paris, France"
            contact="+33 7 49 54 90 53"
            email="customerservice@letservu.com"
            variant="office"
          />
        </Grid>
      </Grid>
      <Grid
        sx={{
          px: { xs: 2, md: 8 }, // Padding horizontal
          py: { xs: 2, md: 3 }, // Padding vertical
          backgroundColor: "#f0f0f0", // Couleur de fond
        }}
        container
        marginTop={4}
        justifyContent="center"
        spacing={2}
      >
        <Grid
          xs={12}
          item
          container
          sx={{ justifyContent: "center", marginBottom: 7, marginTop: 8 }}
        >
          <GoogleMap />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NewContact;
