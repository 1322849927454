/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Container, Grid } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { useTheme } from '@mui/material/styles';

function createMapOptions(maps) {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    },
    mapTypeControl: true
  };
}

function GoogleMap() {
  const theme = useTheme();
  const [center, setcenter] = useState({ lat: 29.7604, lng: -95.3698 });
  const [zoom, setzoom] = useState(11);

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

   // Liste des points
   const points = [
    { id: 1, lat: 48.8566, lng: 2.3522, color: theme.palette.primary.main },
    { id: 2, lat: 29.7604, lng: -95.3698, color: theme.palette.primary.main },
    { id: 3, lat: 45.4215, lng: -75.6972, color: theme.palette.primary.main }
  ];

  return (
    <Container sx={{ height: '60vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyACKGvYt9E1hEHcW2moGh0wBjPLC_egU84'
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={createMapOptions}
        hoverDistance={40 / 2}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
      {points.map((point) => (
        <Grid lat={point.lat} lng={point.lng}>
          <PinDropIcon
            sx={{ color: point.color, fontSize: 24 }}
          />
        </Grid>
      ))}
       
      </GoogleMapReact>
    </Container>
  );
}

export default GoogleMap;
