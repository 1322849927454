import { CompanyAddressInterface } from '../interfaces/companyCreation/companyAddress.interface';
import { CompanyInfoInterface } from '../interfaces/companyCreation/companyInfo.interface';
import { EmployerAddressInterface } from '../interfaces/companyCreation/employerAddress.interface';
import { EmployeeInfoInterface } from '../interfaces/companyCreation/employerInfo.interface';

const setInLocalStorageCompanyInfo = (data: CompanyInfoInterface) => {
  localStorage.setItem('companyName', data.companyName);
  localStorage.setItem('companyFormation', data.companyFormation);
  localStorage.setItem('email', data.email);
  // localStorage.setItem('paymentMethod', data.paymentMethod);
  localStorage.setItem('numberOfLocations', `${data.numberOfLocations}`);
  localStorage.setItem('servicePlan', data.servicePlan);
  localStorage.setItem('type', data.type);
};

const getLocalStorageCompanyInfo = () => {
  const data = {
    companyName: localStorage.getItem('companyName'),
    email: localStorage.getItem('email'),
    // paymentMethod: localStorage.getItem('paymentMethod'),
    numberOfLocations: localStorage.getItem('numberOfLocations'),
    servicePlan: localStorage.getItem('servicePlan'),
    type: localStorage.getItem('type'),
    companyFormation: localStorage.getItem('companyFormation')
  };
  return data;
};

const setInLocalStorageCompanyAddress = (data: CompanyAddressInterface) => {
  localStorage.setItem('addressLine1', data.addressLine1);
  localStorage.setItem('addressLine2', data.addressLine2);
  localStorage.setItem('city', data.city);
  localStorage.setItem('country', data.country);
  localStorage.setItem('phoneNumber', data.phoneNumber);
  localStorage.setItem('countryCode', `${data.countryCode}`);
  localStorage.setItem('state', data.state);
  localStorage.setItem('zipCode', data.zipCode);
};

const getLocalStorageCompanyAddress = () => {
  const data = {
    addressLine1: localStorage.getItem('addressLine1'),
    addressLine2: localStorage.getItem('addressLine2'),
    city: localStorage.getItem('city'),
    state: localStorage.getItem('state'),
    phoneNumber: localStorage.getItem('phoneNumber'),
    zipCode: localStorage.getItem('zipCode'),
    country: localStorage.getItem('country'),
    countryCode: localStorage.getItem('countryCode')
  };
  return data;
};

const setInLocalStorageEmployerInfo = (data: EmployeeInfoInterface) => {
  localStorage.setItem('dateOfBirthEmploye', `${data.dateOfBirth}`);
  localStorage.setItem('emailEmploye', data.email);
  localStorage.setItem('firstNameEmploye', data.firstName);
  localStorage.setItem('pinEmploye', `${data.pin}`);
  localStorage.setItem('lastNameEmploye', data.lastName);
  localStorage.setItem('userNameEmploye', data.userName);
};

const getLocalStorageEmployerInfo = () => {
  const data = {
    firstName: localStorage.getItem('firstNameEmploye'),
    email: localStorage.getItem('emailEmploye'),
    userName: localStorage.getItem('userNameEmploye'),
    pin: localStorage.getItem('pinEmploye'),
    lastName: localStorage.getItem('lastNameEmploye'),
    dateOfBirth: localStorage.getItem('dateOfBirthEmploye')
  };
  return data;
};

const setInLocalStorageEmployerAddress = (data: EmployerAddressInterface) => {
  localStorage.setItem('addressLine1Employe', data.addressLine1);
  localStorage.setItem('addressLine2Employe', data.addressLine2);
  localStorage.setItem('phoneNumberEmploye', data.phoneNumber);
  localStorage.setItem('cityEmploye', data.city);
  localStorage.setItem('countryEmploye', data.country);
  localStorage.setItem('zipCodeEmploye', `${data.zipCode}`);
  localStorage.setItem('stateEmploye', data.state);
};

function getLocalStorageEmployerAddress() {
  const employee = {
    addressLine1: localStorage.getItem('addressLine1Employe'),
    phoneNumber: localStorage.getItem('phoneNumberEmploye'),
    addressLine2: localStorage.getItem('addressLine2Employe'),
    city: localStorage.getItem('cityEmploye'),
    state: localStorage.getItem('stateEmploye'),
    zipCode: localStorage.getItem('zipCodeEmploye'),
    country: localStorage.getItem('countryEmploye')
  };
  return employee;
}

export {
  getLocalStorageCompanyInfo,
  getLocalStorageEmployerInfo,
  setInLocalStorageCompanyInfo,
  setInLocalStorageEmployerInfo,
  getLocalStorageCompanyAddress,
  getLocalStorageEmployerAddress,
  setInLocalStorageCompanyAddress,
  setInLocalStorageEmployerAddress
};
