import React from "react";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { WEB_MAX_WIDTH } from "../../constants/mediaQueryConstants";
import HomeOurCompanyContent from "../../layouts/home/aboutUs/HomeOurCompanyContent";
import HomeOurCompanyImage from "../../layouts/home/aboutUs/HomeOurCompanyImage";
import Banner2 from "../../layouts/home/banner/Banner2";
import HomeBlogLayout from "../../layouts/home/blogs/HomeBlogLayout";
import HomeChooseUsContent from "../../layouts/home/ChooseUs/HomeChooseUsContent";
import HomeChooseUsImage from "../../layouts/home/ChooseUs/HomeChooseUsImage";
import HomeServiceLayout from "../../layouts/home/services/HomeServiceLayout";

export default function ServicesScreen() {
  const theme = useTheme();
  return (
    <Grid
      sx={{
        overflowX: "hidden",
        background: theme.palette.primary.light,
        px: 1,
      }}
    >
      <Grid item xs={12} sx={{ mt: 16 }}>
        <HomeServiceLayout />
      </Grid>
      <Grid container item sx={{ justifyContent: "center" }}>
        <Grid
          container
          item
          spacing={3}
          sx={{
            mt: 5,
            p: 5,
            width: WEB_MAX_WIDTH,
            background: theme.palette.secondary.dark,
          }}
        >
          <Grid item xs={12} md={6}>
            <HomeOurCompanyContent />
          </Grid>
          <Grid item xs={12} md={6} sx={{ pr: 3 }}>
            <HomeOurCompanyImage />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sx={{ justifyContent: "center" }}>
        <Grid
          container
          item
          spacing={3}
          sx={{ mt: 5, px: 5, width: WEB_MAX_WIDTH }}
        >
          <Grid item xs={12} md={6}>
            <HomeChooseUsImage />
          </Grid>
          <Grid item xs={12} md={6} sx={{ pr: 3 }}>
            <HomeChooseUsContent />
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid
        item
        xs={12}
        sx={{ mt: 5, p: 5, background: theme.palette.secondary.dark }}
      >
        <HomeBlogLayout />
      </Grid> */}
      <Grid item xs={12} sx={{ mb: 5 }}>
        <Banner2 />
      </Grid>
    </Grid>
  );
}
